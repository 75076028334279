import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, makeObservable, computed } from "mobx";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { ToolModelType } from "../NewTool/helper";
import { SpinnerLoader } from "../Icons";

@inject("store")
@observer
class TestModal extends Component {
  @observable prompts = [];
  @observable engines = [];
  @observable roles = [];
  @observable variables = [];
  @observable variableInputs = [];
  @observable idxSelection;
  @observable selection;
  @observable outputs = [];

  @observable loading = false;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.prompts = [this.props.initialPrompt];
    this.engines = [ToolModelType["gpt-3.5-turbo"].key];
    this.roles = [this.props.initialRole];
    this.variables = [...this.props.initialVariables];
    this.variableInputs = [{}];
    this.outputs = [[""]];
    this.pickedPolishedPrompt = this.props.pickedPolishedPrompt;
  }

  @computed get showModal() {
    return this.props.showModal;
  }

  addVariable = () => {
    this.variables = [...this.variables, this.selection];
  };

  removeVariable = word => {
    this.variables = this.variables.filter(val => val !== word);
  };

  changePrompt = (val, selection, idx) => {
    this.prompts[idx] = val;
    if (selection) {
      this.selection =
        selection.start === selection.end
          ? ""
          : val.substr(selection.start, selection.end - selection.start);
      this.idxSelection = idx;
    }
  };

  pickPrompt = (idx) => {
    this.pickedPolishedPrompt(this.prompts[idx]);
    this.props.setShowModal(false);
  }

  addPrompt = () => {
    this.prompts = [...this.prompts, this.props.initialPrompt];
    this.engines = [...this.engines, ToolModelType["gpt-3.5-turbo"].key];
    const outputs = [];
    this.variableInputs.forEach(_el => outputs.push(""));
    this.outputs = [...this.outputs, outputs];
  };

  addVariableInput = () => {
    this.variableInputs = [...this.variableInputs, {}];
    this.outputs.forEach(out => {
      out.push("");
    });
  };

  onChangeInput = (el, val, idx) => {
    this.variableInputs[idx][el] = val;
  };

  onChangeToolPromptModel = (val, idx) => {
    this.engines[idx] = val;
  };

  onChangePromptRole = (val, idx) => {
    this.roles[idx] = val;
  };

  run = () => {
    this.variableInputs.forEach((input, idx) => {
      this.prompts.forEach(async (pr, id) => {
        let output = `${pr}`;
        Object.entries(input).forEach(([key, value]) => {
          while (output.includes(key)) {
            output = output.replace(key, value);
          }
        });

        this.loading = true;
        const response = await this.props.store.api.post(
          "/ai/tools/-/internal-helper",
          {
            prompt: output,
            "--internal-use-engine": this.engines[id],
            "--internal-use-role": this.roles[id],
            "--internal-no-history": true,
          }
        );
        this.loading = false;
        this.outputs[id][idx] = response.data.output.trim();
      });
    });
  };

  render() {
    const Variables =
      this.variables &&
      this.variables.map(val => (
        <div key={val} className="px-2 text-sm">
          {val}
          <button
            className="ml-2 hover:bg-red-600 bg-red-500 font-medium rounded-md text-xs px-1 bg-gray-200 text-white border border-gray-300 inline-block"
            onClick={() => this.removeVariable(val)}
          >
            X
          </button>
        </div>
      ));
    const VariablesInputs =
      this.variableInputs &&
      this.variables &&
      this.variableInputs.map((val, idx) => {
        const inputs = this.variables.map(el => (
          <div key={el}>
            <label className="text-gray-400 text-sm block inline-block text-left">
              {el}
            </label>
            <input
              type="text"
              id={el}
              className="block text-sm text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={e => this.onChangeInput(el, e.target.value, idx)}
              placeholder="Value..."
            />
          </div>
        ));
        return (
          <div key={Math.random()}>
            <Divider />
            <>{inputs}</>
          </div>
        );
      });

    return (
      <>
        {this.showModal ? (
          <>
            <div className="h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="max-h-full relative w-auto my-6 mx-auto max-w-3xl lg:w-3/5 md:w-2/3">
                {/* content */}
                <div className="fixed border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/* header */}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">Prompt testing</h3>

                    <button
                      className={`ml-2 ${
                        this.loading
                          ? "bg-green-300 text-white"
                          : "hover:bg-green-600 bg-green-500 text-white"
                      } font-medium rounded-md text-md px-1 py-1 bg-gray-200 border border-gray-300 inline-block`}
                      onClick={() => this.run()}
                      disabled={this.loading}
                    >
                      <div className="flex flex-row items-center">
                        {this.loading && <SpinnerLoader />}
                        {this.loading ? (
                          <div className="ml-2">Running...</div>
                        ) : (
                          "Run Tests"
                        )}
                      </div>
                    </button>

                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => this.props.setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/* body */}
                  <div className="flex flex-1 flex-row">
                    <div className="w-1/2 p-2 flex-col">
                      <div className="flex flex-row justify-between">
                        <div>Prompts</div>

                        <button
                          className="ml-2 hover:bg-green-600 bg-green-500 font-medium rounded-md text-xs px-1 py-1 bg-gray-200 text-white border border-gray-300 inline-block"
                          onClick={() => this.addPrompt()}
                        >
                          + Add prompt
                        </button>
                      </div>

                      <div className="max-h-full overflow-y-auto">
                        {this.prompts.map((prompt, idx) => (
                          <div key={idx} className="mt-2">
                            <Divider />

                            <label className="text-gray-400 text-sm block inline-block text-left">
                              Tool Prompt Model
                            </label>
                            <select
                              id="select-tool-prompt"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={this.engines[idx]}
                              onChange={e =>
                                this.onChangeToolPromptModel(
                                  e.target.value,
                                  idx
                                )
                              }
                            >
                              {Object.values(ToolModelType).map(
                                ({ key, text }) => (
                                  <option key={key} value={key}>
                                    {text}
                                  </option>
                                )
                              )}
                            </select>
                            {(this.engines[idx] ===
                              ToolModelType["gpt-3.5-turbo"].key ||
                              this.engines[idx] ===
                                ToolModelType["gpt-3.5-turbo-chat"].key ||
                              this.engines[idx] ===
                                ToolModelType["gpt-4"].key ||
                              this.engines[idx] ===
                                ToolModelType["gpt-4-chat"].key) && (
                              <>
                                <label className="text-gray-400 text-sm block inline-block text-left">
                                  Prompt System Role
                                </label>
                                <input
                                  type="text"
                                  id="tool-role"
                                  className="pl-2 block text-md text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  onChange={e =>
                                    this.onChangePromptRole(e.target.value, idx)
                                  }
                                  value={this.roles[idx]}
                                  placeholder="Prompt role..."
                                />
                              </>
                            )}
                            <label className="text-gray-400 text-sm block inline-block text-left">
                              Prompt
                            </label>
                            <HighlightWithinTextarea
                              value={prompt}
                              onChange={(val, sel) =>
                                this.changePrompt(val, sel, idx)
                              }
                              highlight={this.variables}
                              placeholder=""
                            />
                            <button
                              className="ml-2 hover:bg-green-600 bg-green-500 font-medium rounded-md text-xs px-1 py-1 bg-gray-200 text-white border border-gray-300 inline-block"
                              onClick={() => this.pickPrompt(idx)}
                            >
                              Pick this prompt
                            </button>

                            <button
                              className={`
                              ${
                                this.selection
                                  ? "hover:bg-green-600 bg-green-500"
                                  : "bg-gray-300"
                              } font-xs rounded-md text-xs px-1 py-1 text-white mt-1 border border-gray-300 inline-block`}
                              disabled={
                                this.idxSelection !== idx && this.selection
                              }
                              onClick={() => this.addVariable()}
                            >
                              Add Variable
                            </button>

                            {this.variableInputs.map((_out, id) => (
                              <textarea
                                id="message"
                                key={id}
                                rows="4"
                                className="block p-2.5 mt-1 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                disabled
                                placeholder={`Output ${id + 1}...`}
                                value={this.outputs[idx][id]}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="w-1/2 p-2 flex-col">
                      <div>Variables</div>
                      <div className="pl-2">{Variables}</div>

                      <div className="flex flex-row justify-between py-2">
                        <div>Variable inputs</div>

                        <button
                          className="ml-2 hover:bg-green-600 bg-green-500 font-medium rounded-md text-xs px-1 py-1 bg-gray-200 text-white border border-gray-300 inline-block"
                          onClick={() => this.addVariableInput()}
                        >
                          + Add input
                        </button>
                      </div>

                      {VariablesInputs}
                    </div>
                  </div>
                  {/* footer */}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-green-500 text-white hover:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => this.props.setShowModal(false)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        ) : null}
      </>
    );
  }
}

export function Divider() {
  return (
    <div className="divide-y-2 divide-dashed divide-gray-300 py-1 md:py-1">
      {" "}
      <div />
      <div />
    </div>
  );
}

export default TestModal;
