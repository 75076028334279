import React, { Component } from "react";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import { Title } from "../Dashboard";

@inject("store")
@observer
class ToolHistory extends Component {
  @observable events;
  @observable tool = this.props.tool;
  @observable historyVersion = this.props.tool.historyVersion;
  @observable isVisible = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentDidUpdate() {
    if (this.tool.historyVersion - this.historyVersion > 0) {
      await this.refresh();
      this.historyVersion = this.tool.historyVersion;
    }
  }

  async refresh() {
    if (!this.props.historyId) return;
    const history = await this.props.store.api
      .get(`/tool/history/${this.props.historyId}`)
      .then(({ data }) => data);
    this.events = history;
  }

  async toggleVisible() {
    this.isVisible = !this.isVisible;
    if (this.isVisible) await this.refresh();
  }

  render() {
    if (!this.events) return null;
    return (
      <>
        <div className="flex flex-col">
          <Title
            title={`Tool History '${this.tool.toolName}${
              this.tool.historyVersion ? "" : ""
            }'`}
          />
          <button
            className="hover:bg-green-600 bg-green-500 font-medium rounded-lg text-md px-4 py-2 bg-gray-200 text-white border border-gray-300"
            onClick={() => this.toggleVisible()}
          >
            See History
          </button>
        </div>

        <div className="flex flex-col">
          {this.isVisible &&
            (this.events.length ? (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-2 py-3 text-center">
                      Select
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Project Title
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Engine
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Input
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Output
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.events.map(el => (
                    <tr
                      key={Math.random()}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th scope="row" className="text-center">
                        {el.toolState ? (
                          <button
                            className="bg-green-500 hover:bg-green-600 text-white p-1 rounded-sm"
                            onClick={() => this.props.revertTool(el.toolState)}
                          >
                            Revert
                          </button>
                        ) : (
                          <></>
                        )}
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {el.tool}
                      </th>
                      <td className="px-6 py-4">{el.engine}</td>
                      <td className="break-all">
                        <div className="flex overflow-scroll max-h-20 px-3 py-2">
                          {el.inputPrompt}
                        </div>
                      </td>
                      <td className="break-all">
                        <div className="flex overflow-scroll max-h-20 px-3 py-2">
                          {el.output}
                        </div>
                      </td>
                      <td className="px-6 py-4">{el.created}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No history events yet</div>
            ))}
        </div>
      </>
    );
  }
}

export default ToolHistory;
