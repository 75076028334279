import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { observable, makeObservable, computed } from "mobx";
import {
  DuplicateIcon,
  CodeIcon,
  TerminalIcon,
  PencilAltIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Shortcut, Tooltip } from "../Components/Output";
import { IconPlus } from "../Icons";
import { ToolModelType } from "../NewTool/helper";

const { v4: uuidv4 } = require("uuid");

@inject("store")
@observer
class ShareModal extends Component {
  @observable rangeValue;
  @observable tokenId;
  @observable userCredits;
  @observable links;
  @observable canUseUserCredits = false;

  @observable codeLink = "";
  @observable apiLink = "";
  @observable apiKey;
  @observable apiBody = {};

  @observable editingLink = "";
  editingValue = -1;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.rangeValue = 50;
    this.tokenId = uuidv4();
    this.userCredits = +this.props.store.profile.credits;
  }

  async componentDidMount() {
    await this.refresh();
  }

  onChangeRangeValue = val => {
    if (+val < 1) return;
    this.rangeValue = +val;
  };

  onChangeCanUseCreditsValue = val => {
    this.canUseUserCredits = val;
  };

  @computed get showModal() {
    return this.props.showModal;
  }

  @computed get isChat() {
    return Object.values(this.props.tool.aiPrompts).some(
      el =>
        el.model === ToolModelType["gpt-3.5-turbo-chat"].key ||
        el.model === ToolModelType["gpt-4-chat"].key
    );
  }

  async addLink() {
    await this.props.store.api.post("/tool/tool-token", {
      tokenId: this.tokenId,
      tool: this.props.tool.id,
      credits: this.rangeValue,
      canUseUserCredits: this.canUseUserCredits,
    });
    await this.refresh();
    this.tokenId = uuidv4();
  }

  async updateLink() {
    await this.props.store.api.post(`/tool/tool-token/${this.editingLink}`, {
      credits: this.editingValue,
    });
    this.editingLink = "";
    await this.refresh();
  }

  async removeLink(token) {
    await this.props.store.api.delete(`/tool/tool-token/${token}`);
    await this.refresh();
    this.tokenId = uuidv4();
  }

  async refresh() {
    this.links = await this.props.store.api
      .get(`/tool/tool-token/${this.props.tool.id}`)
      .then(({ data }) => data);
  }

  render() {
    return (
      <>
        {this.showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl lg:w-3/5 md:2/3">
                {/* content */}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/* header */}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {`Share Options '${this.props.tool.title}'`}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => this.props.setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/* body */}
                  <div className="flex flex-1 flex-row">
                    <input
                      name="link"
                      id="link"
                      className="outline-none focus:outline-none text-lg bg-white rounded-md py-2 border  focus:border-gray-400  border-gray-300 font-regular mt-2 transition-all pl-2 ml-5 w-2/3"
                      disabled
                      defaultValue={`${this.props.store.domain}${
                        this.isChat ? "tools-chat" : "tools"
                      }/${this.tokenId}`}
                    />
                    <Shortcut
                      className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                      onClick={() => this.addLink()}
                    >
                      <IconPlus className="w-10 h-10" />
                      <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                        <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                          Add Shared Link
                        </span>
                      </Tooltip>
                    </Shortcut>
                  </div>
                  <div className="mx-5 my-2">
                    <label>Link can use</label>
                    <input
                      name="credits"
                      id="credits"
                      type="number"
                      className="outline-none focus:outline-none text-lg bg-white rounded-md border  focus:border-gray-400  border-gray-300 font-regular mt-2 transition-all pl-2 mx-1 w-16"
                      disabled={this.canUseUserCredits}
                      value={this.rangeValue}
                      min="1"
                      onChange={e => this.onChangeRangeValue(e.target.value)}
                    />
                    <label>credits</label>
                  </div>
                  <div className="mx-5 my-2">
                    <input
                      type="checkbox"
                      id="use-credits"
                      name="use-credits"
                      className="bg-blue-500 checked:bg-blue-600"
                      value={this.canUseUserCredits}
                      onChange={e =>
                        this.onChangeCanUseCreditsValue(e.target.checked)
                      }
                    />
                    <label htmlFor="use-credits" className="ml-1">
                      Link can use all my credits
                    </label>
                    <br />
                  </div>

                  <div className="relative p-6 flex-auto">
                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                      Active Links:
                      {this.links?.map((el, idx) => (
                        <div key={Math.random()}>
                          <>
                            {el.tokenId !== this.editingLink && (
                              <div className="flex flex-1 flex-row">
                                <div>{`${idx + 1}. ${
                                  el.canUseUserCredits
                                    ? this.userCredits
                                    : el.credits
                                } credits available`}</div>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => {
                                    this.editingLink = el.tokenId;
                                    this.editingValue = el.canUseUserCredits
                                      ? this.userCredits
                                      : el.credits;
                                  }}
                                >
                                  <PencilAltIcon className="w-5 h-5" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      Edit Credits
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() =>
                                    this.props.store.copyToClipboard(
                                      `${this.props.store.domain}${
                                        this.isChat ? "tools-chat" : "tools"
                                      }/${el.tokenId}`
                                    )
                                  }
                                >
                                  <DuplicateIcon className="w-5 h-5" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      Copy Link
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => {
                                    this.codeLink = `${
                                      this.props.store.domain
                                    }${this.isChat ? "tools-chat" : "tools"}/${
                                      el.tokenId
                                    }`;
                                  }}
                                >
                                  <CodeIcon className="w-5 h-5" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      See iframe code
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => {
                                    this.apiLink = `${this.props.store.api.defaults.baseURL}public-tools/tools/tools/1`;
                                    const defaultKey = Object.keys(
                                      this.props.tool.aiPrompts
                                    )[0];
                                    this.apiKey = defaultKey;
                                    this.apiBody = {};
                                    this.apiBody.currentPrompt = defaultKey;
                                    this.apiBody.tokenId = el.tokenId;
                                    this.props.tool.aiPrompts[
                                      defaultKey
                                    ].variables.forEach(variable => {
                                      this.apiBody[variable] = `<${variable}>`;
                                    });
                                  }}
                                >
                                  <TerminalIcon className="w-5 h-5" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      See api description
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => this.removeLink(el.tokenId)}
                                >
                                  <div className="w-5 h-5 flex items-center pl-1">
                                    X
                                  </div>
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      Remove Link
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                              </div>
                            )}
                            {el.tokenId === this.editingLink && (
                              <div className="flex flex-1 flex-row">
                                <div>{`${idx}. `}</div>
                                <input
                                  type="number"
                                  defaultValue={this.editingValue}
                                  className="border mx-2"
                                  onChange={e => {
                                    this.editingValue = e.target.value;
                                  }}
                                />
                                <div> credits</div>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => {
                                    this.updateLink();
                                  }}
                                >
                                  <CheckIcon className="w-5 h-5 text-green-500" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      Confirm
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                                <Shortcut
                                  className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                                  onClick={() => {
                                    this.editingLink = "";
                                  }}
                                >
                                  <XIcon className="w-5 h-5 text-red-500" />
                                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                    <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                      Cancel
                                    </span>
                                  </Tooltip>
                                </Shortcut>
                              </div>
                            )}
                          </>
                        </div>
                      ))}
                    </div>

                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                      <div className="flex justify-start">
                        <div>Code: </div>
                        {this.codeLink ? (
                          <Shortcut
                            className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                            onClick={() =>
                              this.props.store.copyToClipboard(
                                `<iframe src="${this.codeLink}" title="${this.props.tool.title}" width="800" height="1200"></iframe>`
                              )
                            }
                          >
                            <DuplicateIcon className="w-5 h-5" />
                            <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                              <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                Copy Code
                              </span>
                            </Tooltip>
                          </Shortcut>
                        ) : null}
                      </div>
                      {this.codeLink ? (
                        <div className="w-min">
                          <CodeEditor
                            style={{
                              fontFamily: "JetBrains Mono",
                              backgroundColor: "#f5f5f5",
                              fontSize: "0.7rem",
                            }}
                            padding={5}
                            data-color-mode="dark"
                            language="html"
                            value={`<iframe src="${this.codeLink}" title="${this.props.tool.title}" width="800" height="1200"></iframe>`}
                          />
                        </div>
                      ) : (
                        <>No Link Selected</>
                      )}
                    </div>

                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                      <div className="flex justify-start">
                        <div>Api Description: </div>
                      </div>
                      {this.apiLink ? (
                        <div className="w-full">
                          {Object.keys(this.props.tool.aiPrompts).length >
                            1 && (
                            <div className="w-full">
                              <div className="flex justify-start w-full text-sm">
                                <div>Api prompt: </div>
                              </div>
                              <div className="w-full">
                                <select
                                  id="select-api-key"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  value={this.apiKey}
                                  onChange={e => {
                                    this.apiKey = e.target.value;
                                    const token = this.apiBody.tokenId;
                                    this.apiBody = {};
                                    this.apiBody.currentPrompt = this.apiKey;
                                    this.apiBody.tokenId = token;
                                    this.props.tool.aiPrompts[
                                      this.apiKey
                                    ].variables.forEach(variable => {
                                      this.apiBody[variable] = `<${variable}>`;
                                    });
                                  }}
                                >
                                  {Object.keys(this.props.tool.aiPrompts).map(
                                    key => (
                                      <option key={key} value={key}>
                                        {key}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          )}

                          <div className="flex justify-start w-full text-sm items-center">
                            <div>Api Link: </div>
                            <Shortcut
                              className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                              onClick={() =>
                                this.props.store.copyToClipboard(this.apiLink)
                              }
                            >
                              <DuplicateIcon className="w-4 h-4" />
                              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                  Copy Link
                                </span>
                              </Tooltip>
                            </Shortcut>
                          </div>

                          <div className="w-full">
                            <input
                              name="api link"
                              id="api-link"
                              className="outline-none focus:outline-none text-sm bg-white rounded-md border focus:border-gray-400 border-gray-300 font-regular transition-all pl-2 w-full"
                              disabled
                              defaultValue={this.apiLink}
                            />
                          </div>
                          <div className="flex justify-start w-full text-sm items-center">
                            <div>Api Body: </div>
                            <Shortcut
                              className="mx-2 p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative grid flex flex-col items-center text-gray-500"
                              onClick={() =>
                                this.props.store.copyToClipboard(
                                  JSON.stringify(this.apiBody)
                                    .replace(/,/g, ",\n")
                                    .replace("{", "{\n")
                                    .replace("}", "\n}")
                                )
                              }
                            >
                              <DuplicateIcon className="w-4 h-4" />
                              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                  Copy Body
                                </span>
                              </Tooltip>
                            </Shortcut>
                          </div>
                          <div className="w-full">
                            <CodeEditor
                              style={{
                                fontFamily: "JetBrains Mono",
                                backgroundColor: "#f5f5f5",
                                fontSize: "0.7rem",
                              }}
                              padding={5}
                              data-color-mode="dark"
                              language="javascript"
                              value={JSON.stringify(this.apiBody)
                                .replace(/,/g, ",\n")
                                .replace("{", "{\n")
                                .replace("}", "\n}")}
                            />
                          </div>
                        </div>
                      ) : (
                        <>No Link Selected</>
                      )}
                    </div>
                  </div>
                  {/* footer */}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-green-500 text-white hover:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => this.props.setShowModal(false)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        ) : null}
      </>
    );
  }
}

export default ShareModal;
