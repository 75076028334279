import axios from "axios";
import { observable, computed, makeObservable, configure } from "mobx";
import FuzzySet from "fuzzyset";
import Filter from "bad-words";
import Models from "./Models";

import config from "./config";
import { ToolCategories } from "./NewTool/helper";

const filterBadWords = new Filter();
const { Tool } = Models;

const { baseURL } = config;
const { v4: uuidv4 } = require("uuid");

configure({ enforceActions: "never" });

const api = axios.create({ baseURL });

class appStore {
  api = api;
  @observable baseURL = baseURL;
  @observable redirect = ``;
  @observable editor;
  @observable editorIsLoading = true;

  // User Profile
  @observable profile = {};
  @observable token;
  @observable isLoggedIn = false;
  @observable isAdmin = false;
  @observable isCreator = false;
  @observable loginLoading = false;

  @observable landingPageUrl = config.landingPageUrl;
  @observable domain = config.domain;

  @observable toolsBk = [];
  @observable myToolsBk = [];
  @observable bookmarksBk = [];

  @observable uniqueCategories = [];

  @observable currentTool = {};

  editor;
  visitorId;

  constructor() {
    makeObservable(this);
    this.init();
    // Check credits every time, and log out people who aren't authenticated
    this.api.interceptors.response.use(
      response => {
        this.updateCredits(response);
        return response;
      },
      error => {
        console.log(error);
        console.log(`error.response.statusText`, error.response.statusText);
        if (
          error.response &&
          error.response.statusText === "Token Authentication Failed"
        ) {
          this.handleLogout();
        }
        if (
          error.response &&
          error.response.statusText === "No Credit Remaining"
        ) {
          this.noCreditsRemainPrompt();
        }
        return Promise.reject(error);
      }
    );
  }

  noCreditsRemainPrompt = () => {
    // set the browser url to the no-credits page
    window.location.pathname = "/my-profile";
  };

  init = async () => {
    try {
      this.referralTrackingCode();

      if (navigator.cookieEnabled) {
        const profile = localStorage.getItem("profile");
        const token = localStorage.getItem("token");
        if (profile && token) {
          this.api.defaults.headers.common["x-access-token"] = token;
          this.token = token;
          this.profile = JSON.parse(profile);
          this.isLoggedIn = true;
          this.isAdmin = this.profile.accountType === "admin";
          this.isCreator =
            this.profile.accountType === "admin" ||
            this.profile.accountType === "creator";
          this.refreshTokenAndProfile();
          this.getTools();
          if (this.isCreator) this.getMyTools();
          this.getBookmarks();
        }
      }
    } catch (err) {
      this.profile = "";
      this.isLoggedIn = false;
      console.log(err);
    }
  };

  @observable referral = "";

  referralTrackingCode = async () => {
    const referral = new URLSearchParams(window.location.search).get(
      "referral"
    );
    if (referral) {
      this.setReferral(referral);
    } else {
      this.initReferral();
    }
  };

  setReferral = async referral => {
    this.referral = referral;
    if (navigator.cookieEnabled) {
      localStorage.setItem("referral", JSON.stringify(referral));
    }
  };

  initReferral = async () => {
    if (navigator.cookieEnabled) {
      const referral = localStorage.getItem("referral");
      this.referral = referral;
    }
  };

  loginWithDataTokenAndProfile = async data => {
    this.setToken(data.token);
    this.setProfile(data.profile);
    this.isLoggedIn = true;
  };

  refreshTokenAndProfile = async () => {
    try {
      const response = await this.api
        .post("/user/refresh/profile")
        .then(({ data }) => data);
      if (response) {
        this.setProfile(response.profile);
      }
    } catch (err) {
      console.log(err);
      this.handleLogout();
    }
  };

  getTools = async (sharedApp = "") => {
    try {
      const result = await this.api
        .get("/tool/tools", sharedApp ? { params: { sharedApp } } : {})
        .then(({ data }) => data.map(el => Tool.fromJSON(el)));
      const out = result;
      this.toolsBk = out;

      this.uniqueCategories = [...ToolCategories];
      this.toolsBk.forEach(tool => {
        if (!this.uniqueCategories.includes(tool.category)) {
          this.uniqueCategories = [...this.uniqueCategories, tool.category];
        }
      });

      return out;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  getMyTools = async () => {
    try {
      const result = await this.api
        .get("/tool/my-tools")
        .then(({ data }) => data.map(el => Tool.fromJSON(el)));
      this.myToolsBk = result;
    } catch (err) {
      console.log(err);
    }
  };

  getBookmarks = async () => {
    try {
      const result = await this.api
        .get("/tool/bookmarks")
        .then(({ data }) => data);
      this.bookmarksBk = result;
    } catch (err) {
      console.log(err);
    }
  };

  toggleBookmark = async tool => {
    try {
      await this.api.post("/tool/bookmarks", { tool }).then(({ data }) => data);
      await this.getBookmarks();
    } catch (err) {
      console.log(err);
    }
  };

  isBookmarked = tool => this.bookmarksBk.includes(tool);

  setToken = async token => {
    this.api.defaults.headers.common["x-access-token"] = token;
    if (navigator.cookieEnabled) {
      localStorage.setItem("token", token);
    }
  };

  setProfile = async profile => {
    this.profile = profile;
    if (navigator.cookieEnabled) {
      localStorage.setItem("profile", JSON.stringify(profile));
    }
  };

  handleLogout = () => {
    this.isLoggedIn = false;
    this.profile = {};
    this.api.defaults.headers.common["x-access-token"] = "";
    if (navigator.cookieEnabled) {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
    }
  };

  @observable toolsKeyword = "";
  onChangeToolsKeyword = e => {
    this.toolsKeyword = e.target.value;
  };

  @computed get FuzzySearch() {
    return FuzzySet(this.toolsBk ? this.toolsBk.map(tool => tool.title) : []);
  }
  @computed get tools() {
    // let tools = TOOLS.filter(tool => tool.title.toLowerCase().includes(this.toolsKeyword.toLowerCase()))
    const fuzzyTools = this.FuzzySearch.get(this.toolsKeyword, 0.5);
    if (fuzzyTools && fuzzyTools.length) {
      const fuzzySummary = fuzzyTools.map(fuzzyTool => fuzzyTool[1]);
      if (fuzzySummary && fuzzySummary.length) {
        return this.toolsBk.filter(tool => fuzzySummary.includes(tool.title));
      }
    }
    return this.toolsBk;
  }

  @computed get myTools() {
    return this.myToolsBk;
  }

  @computed get bookmarks() {
    return this.toolsBk.filter(el => this.bookmarksBk.includes(el._id));
  }

  getToolByTitle = (title, user) => this.toolsBk.find(tool => tool.title === title && tool.createdBy === user);
  getToolByUrl = url => this.toolsBk.find(tool => tool.to === url);

  @observable error = "";
  checkPrompt = ({ value, attr }) => {
    if (filterBadWords.isProfane(value)) {
      // eslint-disable-next-line no-throw-literal
      throw {
        success: false,
        attr,
        value: value.replace(/^\s+|\s+$/g, ""),
        message: "Unsafe content detected, please try different language",
      };
    }
    if (value) {
      return {
        success: true,
        attr,
        value: value.replace(/^\s+|\s+$/g, ""),
      };
    }
    return null;
  };
  checkOutput = output => {
    if (output) {
      return output.replace(/^\s+|\s+$/g, "");
    }
    return "";
  };

  updateCredits = async data => {
    try {
      if (data.hasOwnProperty("data")) {
        if (data.data.hasOwnProperty("credits")) {
          this.profile.credits = data.data.credits;
        }
        if (data.data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.data.creditsUsed;
        }
      } else {
        if (data.hasOwnProperty("credits")) {
          this.profile.credits = data.credits;
        }
        if (data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.creditsUsed;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  @observable copyToClipboardText = ``;
  copyToClipboard = output => {
    if (output instanceof Array) {
      output = output.join("\n");
    }
    if (!navigator.clipboard) {
      const textarea = document.getElementById("copy-textarea");
      this.copyToClipboardText = `${output}`;
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      return;
    }
    navigator.clipboard.writeText(output).then(
      () => {
        console.log("Async: Copying to clipboard was successful!");
      },
      err => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  @observable feedback = ``;
  reportToFeedback = output => {
    this.redirect = "/my-profile/feedback";
    this.feedback = `${output}`;
    setTimeout(() => {
      this.redirect = "";
    }, 50);
  };

  setLockedTools = tool => {
    if (navigator.cookieEnabled) {
      const locked = localStorage.getItem("lockedTools");
      const json = [];
      if (locked) {
        const jsonLocked = JSON.parse(locked);
        json.push(...jsonLocked);
        json.push(tool);
      } else {
        json.push(tool);
      }
      localStorage.setItem('lockedTools', JSON.stringify(json));
    }
  }

  isLocked = tool => {
    if (navigator.cookieEnabled) {
      const locked = localStorage.getItem("lockedTools");
      if (!locked) return false;
      const json = JSON.parse(locked);
      return json.includes(tool);
    }
    return false;
  }

  getVisitorId = () => {
    if (navigator.cookieEnabled) {
      const visitorId = localStorage.getItem("visitorId");
      if (visitorId) {
        return visitorId;
      }
      const newVisitorId = uuidv4();
      localStorage.setItem("visitorId", newVisitorId);
      return newVisitorId;
    }

    if (!this.visitorId) {
      this.visitorId = uuidv4();
    }
    return this.visitorId;
  }

  downloadFile = data => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "text/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "analytics.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  getAnalytics = async (id = null) => {
    const res = await this.api.get(`/analytics${id ? `/${id}` : ''}`);
    const headers = [
      "Created,Tool,Consumed Credits,TokenId,Session,VisitorId,ToolUser",
    ];
    const csvData = res.data.reduce((acc, entry) => {
      const {
        created,
        tool,
        creditsConsumed,
        token,
        sessionId,
        visitorId,
        user,
      } = entry;
      acc.push(
        [
          created,
          tool,
          creditsConsumed,
          token,
          sessionId,
          visitorId,
          user,
        ].join(",")
      );
      return acc;
    }, []);
    const csv = [...headers, ...csvData].join("\n");
    this.downloadFile(csv);
  }
}

export default appStore;
