import React, { Component } from "react";
import { observable, computed, makeObservable } from "mobx";
import { UserCircleIcon } from "@heroicons/react/outline";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  checkIfHeading,
  checkIfValue,
  ToolModelType,
  ToolType,
  UITypes,
  ToolCategories,
  DefaultModel,
  checkIfOutputValue,
  checkIfSubmit,
  checkIfOutputSubmit,
  checkIfOutputAsValue,
  checkIfOutput,
  checkIfNumberValue,
  checkIfSelect,
  checkIfOutputNumberValue,
  checkIfOutputSelect,
  checkIfOutputs,
  checkIfConditionalOutput,
} from "./helper";
import MainBody from "../Components/Body";
import Header from "../Components/Header";
import Models from "../Models";
import { Title } from "../Dashboard";
import ToolPreview from "../Core/ToolPreview";
import ToolHistory from "../Core/ToolHistory";
import Autocomplete from "../Components/Autocomplete";
import TestModal, { Divider } from "../Core/TestModal";

const { NewTool } = Models;

@inject("store")
@observer
class Body extends Component {
  @observable.deep tool;

  @observable selection;
  @observable autoFocus;

  @observable editMode = false;
  @observable error;

  @observable showTestModal = false;

  @observable currentPrompt = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    this.props.store.refreshTokenAndProfile();

    const toolParam = this.props.match.params.tool;
    const userParam = this.props.match.params.user;
    this.editMode = !!toolParam;

    if (this.editMode) {
      const editedTool = this.props.store.getToolByTitle(toolParam, userParam);
      if (editedTool) {
        this.tool = observer(NewTool.fromTool(editedTool));
      } else {
        const tools = await this.props.store.getTools();
        const newEditedTool = tools?.find(
          tool => tool.searchKey === toolParam && tool.createdBy === userParam
        );
        const newTool = NewTool.fromTool(newEditedTool);
        this.tool = observer(newTool);
      }
    } else {
      const newTool = NewTool.init();
      this.tool = observer(newTool);
    }
  }

  onChangeToolName(val) {
    this.tool.toolName = val;
  }

  onChangeToolType(val) {
    this.tool.toolType = val;
  }

  onChangeToolCategory(val) {
    this.tool.toolCategory = val;
  }

  onChangePromptModel(val, idx) {
    this.tool.prompts[idx].model = val;
    this.tool.prompts[idx].role = "";
  }

  onChangePromptKey(val, idx) {
    this.tool.prompts[idx].key = val;
  }

  onChangePromptRole(val, idx) {
    this.tool.prompts[idx].role = val;
  }

  onChangeIntroMessage(val) {
    this.tool.introMessage = val;
  }

  @computed get combinedVariables() {
    const combinedVariables = this.tool.prompts?.reduce((r, val) => {
      const vars = val.variables;

      vars?.forEach(v => {
        r.push({
          promptKey: val.key,
          text: `[${val.key}] ${v}`,
          promptVariable: v,
        });
      });

      return r;
    }, []);
    return combinedVariables;
  }

  @computed get availableUITypes() {
    return this.combinedVariables && this.combinedVariables.length
      ? Object.values(UITypes)
      : Object.values(UITypes).filter(
          el =>
            !checkIfValue(el.key) &&
            !checkIfOutputValue(el.key) &&
            !checkIfOutputAsValue(el.key) &&
            !checkIfNumberValue(el.key) &&
            !checkIfSelect(el.key) &&
            !checkIfOutputNumberValue(el.key) &&
            !checkIfOutputSelect(el.key) &&
            !checkIfConditionalOutput(el.key)
        );
  }

  @computed get defaultUIType() {
    return this.availableUITypes && this.availableUITypes.length
      ? this.availableUITypes[0].key
      : null;
  }

  @computed get promptKeys() {
    return this.tool.prompts.map(p => p.key);
  }

  onChangePrompt = (val, selection, idx) => {
    if (selection) {
      this.selection =
        selection.start === selection.end
          ? ""
          : val.substr(selection.start, selection.end - selection.start);
    }
    this.tool.prompts[idx].prompt = val;
  };

  addVariable = () => {
    this.tool.prompts[this.currentPrompt].variables = [
      ...this.tool.prompts[this.currentPrompt].variables,
      this.selection,
    ];
    this.addUIElement(
      `[${this.tool.prompts[this.currentPrompt].key}] ${this.selection}`
    );
  };

  removeVariable = word => {
    this.tool.prompts[this.currentPrompt].variables = this.tool.prompts[
      this.currentPrompt
    ].variables.filter(val => val !== word);
  };

  addUIElement = el => {
    const key = Math.random();
    this.tool.uiStructure = [
      ...this.tool.uiStructure,
      {
        uiType: this.defaultUIType,
        uiValue:
          el ||
          (this.combinedVariables.length
            ? this.combinedVariables[0].text
            : null),
        key,
        condition: this.promptKeys[0],
      },
    ];
    this.autoFocus = key;
  };

  addPrompt = () => {
    this.tool.prompts = [
      ...this.tool.prompts,
      {
        key: "",
        model: DefaultModel,
        variables: [],
      },
    ];
    this.currentPrompt = this.tool.prompts.length - 1;
  };

  incrementPrompt = (positive = true) => {
    if (positive) {
      this.currentPrompt += 1;
      if (this.currentPrompt === this.tool.prompts.length)
        this.currentPrompt = 0;
    }
    if (!positive) {
      this.currentPrompt -= 1;
      if (this.currentPrompt === -1)
        this.currentPrompt = this.tool.prompts.length - 1;
    }
  };

  removeUIElement = key => {
    this.tool.uiStructure = this.tool.uiStructure.filter(
      val => val.key !== key
    );
  };

  changeUIElementType = (val, idx) => {
    this.tool.uiStructure[idx].uiType = val;
    if (checkIfHeading(val) || checkIfOutput(val))
      this.tool.uiStructure[idx].uiValue = "";
    if (
      checkIfSubmit(val) ||
      checkIfOutputSubmit(val) ||
      checkIfConditionalOutput(val)
    ) {
      const initialValue = this.promptKeys[0];
      this.tool.uiStructure[idx].uiValue = initialValue;
    }
    if (checkIfOutputs[val]) {
      const initialValue = this.promptKeys[0];
      this.tool.uiStructure[idx].condition = initialValue;
    }
  };

  changeUIElementValue = (val, idx) => {
    this.tool.uiStructure[idx].uiValue = val;
  };

  changeUIElementOptions = (val, idx) => {
    this.tool.uiStructure[idx].uiOptions = val;
    this.autoFocus = `${this.tool.uiStructure[idx].key}-options`;
  };

  changeUIMinMaxOptions = (val, idx, type = "min") => {
    if (!val.match(/^\d+\.?\d*$/)) return;
    if (type === "min") {
      val = Math.max(0, val);
      val = Math.min(this.tool.uiStructure[idx].max || 0, val);
      this.tool.uiStructure[idx].min = val;
      this.autoFocus = `${this.tool.uiStructure[idx].key}-min`;
    } else {
      val = Math.max(0, +val);
      val = Math.max(this.tool.uiStructure[idx].min || 0, val);
      this.tool.uiStructure[idx].max = val;
      this.autoFocus = `${this.tool.uiStructure[idx].key}-max`;
    }
  };

  changeUIElementText = (val, idx) => {
    this.tool.uiStructure[idx].uiText = val;
    this.autoFocus = this.tool.uiStructure[idx].key;
  };

  changeUIElementTitle = (val, idx) => {
    this.tool.uiStructure[idx].title = val;
    this.autoFocus = this.tool.uiStructure[idx].key;
  };

  changeUIElementCondition = (val, idx) => {
    this.tool.uiStructure[idx].condition = val;
  };

  changeOutputType = (val, idx) => {
    this.tool.uiStructure[idx].outputType = val;
  };

  createOrEditTool = async () => {
    if (!this.tool.toolName) {
      this.error = "Required tool name.";
      return;
    }
    this.error = null;
    const isChat = this.tool.prompts.some(
      el =>
        el.model === ToolModelType["gpt-3.5-turbo-chat"].key ||
        el.model === ToolModelType["gpt-4-chat"].key
    );
    if (!this.editMode) {
      await this.props.store.api
        .post("/tool/create-tool", {
          title: this.tool.toolName,
          category: this.tool.toolCategory,
          type: this.tool.toolType || ToolType.public.key,
          introMessage: this.tool.introMessage,
          prompts: this.tool.prompts,
          uiStructure: this.tool.uiStructure,
          historyId: this.tool.historyId,
        })
        .then(async res => {
          console.log("Created Tool!", res);

          if (res.data.success) {
            const searchKey = this.tool.toolName
              .toLowerCase()
              .replace(/([^a-zA-z0-9]+)/g, _s0 => "-");
            await this.props.store.getTools();
            this.props.history.push(
              `/${isChat ? "ai-chat" : "ai"}/tools/${
                this.props.store.profile.username
              }/${searchKey}`
            );
          } else {
            this.error = res.data.error;
          }
        });
    } else {
      await this.props.store.api
        .post("/tool/update-tool", {
          title: this.tool.toolName,
          category: this.tool.toolCategory,
          type: this.tool.toolType,
          introMessage: this.tool.introMessage,
          prompts: this.tool.prompts,
          uiStructure: this.tool.uiStructure,
          searchKey: this.tool.searchKey,
          historyId: this.tool.historyId,
          userId: this.tool.userId,
        })
        .then(async res => {
          console.log("Updated Tool!", res);

          if (res.data.success) {
            const searchKey = this.tool.toolName
              .toLowerCase()
              .replace(/([^a-zA-z0-9]+)/g, _s0 => "-");
            await this.props.store.getTools();
            this.props.history.push(
              `/${isChat ? "ai-chat" : "ai"}/tools/${
                this.tool.createdBy
              }/${searchKey}`
            );
          } else {
            this.error = res.data.error;
          }
        });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.tool.uiStructure = arrayMoveImmutable(
      this.tool.uiStructure,
      oldIndex,
      newIndex
    );
  };

  revertTool = exTool => {
    this.tool.toolName = exTool.title;
    this.tool.toolType = exTool.type;
    this.tool.prompts = exTool.prompts;
    this.tool.uiStructure = [...exTool.uiStructure];
    this.tool.searchKey = exTool.searchKey;
    this.tool.historyId = exTool.historyId;
    this.tool.introMessage = exTool.introMessage;
  };

  changeShowTestModal = (val = null) => {
    if (val !== null) {
      this.showTestModal = val;
    } else {
      this.showTestModal = !this.showTestModal;
    }
  };

  pickedPolishedPrompt = val => {
    this.tool.prompts[this.currentPrompt].prompt = val;
  };

  updateHistory() {
    this.tool.historyVersion += 1;
  }

  render() {
    if (!this.tool) return null;
    const canChangePrompts = this.tool.prompts.length > 1;

    const Variables =
      this.tool &&
      this.tool.prompts[this.currentPrompt].variables?.map(val => (
        <div key={val} className="px-2">
          {val}
          <button
            className="hover:bg-red-600 bg-red-500 font-medium rounded-md text-xs px-1 ml-1 bg-gray-200 text-white mt-2 border border-gray-300 inline-block"
            onClick={() => this.removeVariable(val)}
          >
            X
          </button>
        </div>
      ));

    return (
      <div className={`overflow-auto ${this.showTestModal ? "fixed" : ""}`}>
        <Header
          title={
            <input
              type="text"
              id="tool-name"
              required
              className="pl-2 mt-2 block text-lg text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={e => this.onChangeToolName(e.target.value)}
              value={this.tool.toolName}
              placeholder="Tool name..."
            />
          }
          desc="Page for tool creation"
          category="Tool Creation"
          Icon={UserCircleIcon}
          fromColor={this.fromColor}
        />
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <Grid>
            <div className="flex flex-col flex-1">
              <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                Tool Category
              </label>

              {!this.props.store.isAdmin && (
                <select
                  id="select-tool-category"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-sm focus:ring-blue-500 focus:border-blue-500 block px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={this.tool.toolCategory || ToolCategories[0]}
                  onChange={e => this.onChangeToolCategory(e.target.value)}
                >
                  {Object.values(ToolCategories).map(cat => (
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              )}
              {this.props.store.isAdmin && (
                <Autocomplete
                  suggestions={this.props.store.uniqueCategories}
                  inputCb={val => this.onChangeToolCategory(val)}
                  initialValue={this.tool.toolCategory || ToolCategories[0]}
                />
              )}
              <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                Tool Type
              </label>
              <select
                id="select-tool-prompt"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-sm focus:ring-blue-500 focus:border-blue-500 block px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={this.tool.toolType || ToolType.public.key}
                onChange={e => this.onChangeToolType(e.target.value)}
              >
                {Object.values(ToolType).map(({ key, text }) => (
                  <option key={key} value={key}>
                    {text}
                  </option>
                ))}
              </select>

              <div className="flex flex-row justify-between items-center mt-2">
                <label className="text-black text-lg block mt-4 inline-block text-left">
                  Prompts
                </label>

                <button
                  className="bg-green-500 hover:bg-green-600 font-medium rounded-lg text-sm px-1 py-1 text-white mt-4 border border-gray-300 inline-block"
                  onClick={() => this.addPrompt()}
                >
                  + Add Prompt
                </button>
              </div>

              <Divider />

              <label className="text-gray-400 text-sm block mt-2 inline-block text-left">
                Prompt Key
              </label>
              <input
                type="text"
                id="prmpt-key"
                className="pl-2 block text-md text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={e =>
                  this.onChangePromptKey(e.target.value, this.currentPrompt)
                }
                value={this.tool.prompts[this.currentPrompt].key}
                placeholder="Prompt key..."
              />
              <label className="text-gray-400 text-sm block mt-2 inline-block text-left">
                Prompt Model
              </label>
              <select
                id="select-tool-prompt"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-sm focus:ring-blue-500 focus:border-blue-500 block px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={this.tool.prompts[this.currentPrompt].model}
                onChange={e =>
                  this.onChangePromptModel(e.target.value, this.currentPrompt)
                }
              >
                {Object.values(ToolModelType).map(({ key, text }) => (
                  <option key={key} value={key}>
                    {text}
                  </option>
                ))}
              </select>
              {(this.tool.prompts[this.currentPrompt].model ===
                ToolModelType["gpt-3.5-turbo"].key ||
                this.tool.prompts[this.currentPrompt].model ===
                  ToolModelType["gpt-3.5-turbo-chat"].key ||
                this.tool.prompts[this.currentPrompt].model ===
                  ToolModelType["gpt-4"].key ||
                this.tool.prompts[this.currentPrompt].model ===
                  ToolModelType["gpt-4-chat"].key) && (
                <>
                  <label className="text-gray-400 text-sm block mt-2 inline-block text-left">
                    Prompt System Role
                  </label>
                  <textarea
                    type="text"
                    id="tool-role"
                    className="pl-2 block text-md text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={e =>
                      this.onChangePromptRole(
                        e.target.value,
                        this.currentPrompt
                      )
                    }
                    value={this.tool.prompts[this.currentPrompt].role}
                    placeholder="Prompt role..."
                    rows={5}
                  />
                </>
              )}
              {(this.tool.prompts[this.currentPrompt].model ===
                ToolModelType["gpt-3.5-turbo-chat"].key ||
                this.tool.prompts[this.currentPrompt].model ===
                  ToolModelType["gpt-4-chat"].key) && (
                <>
                  <label className="text-gray-400 text-sm block mt-2 inline-block text-left">
                    Intro Message
                  </label>
                  <textarea
                    type="text"
                    id="tool-role"
                    className="pl-2 block text-md text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={e => this.onChangeIntroMessage(e.target.value)}
                    value={this.tool.introMessage}
                    placeholder="Intro message..."
                    rows={5}
                  />
                </>
              )}
              <label className="text-gray-400 text-sm block mt-2 inline-block text-left">
                Prompt
              </label>
              <HighLightArea
                prompt={this.tool.prompts[this.currentPrompt].prompt}
                onChangePrompt={(val, sel) =>
                  this.onChangePrompt(val, sel, this.currentPrompt)
                }
                variables={this.tool.prompts[this.currentPrompt].variables}
              />
              <div className="flex flex-row justify-between mb-1">
                <button
                  className="hover:bg-green-600 bg-green-500 font-medium rounded-md text-sm px-2 py-1 bg-gray-200 text-white mt-1 border border-gray-300 inline-block"
                  onClick={() => this.changeShowTestModal(true)}
                >
                  Prompt Polish
                </button>
                <button
                  className={`${
                    this.selection
                      ? "hover:bg-green-600 bg-green-500"
                      : "bg-gray-300"
                  } font-medium rounded-md text-sm px-2 py-1 text-white mt-1 border border-gray-300 inline-block`}
                  disabled={!this.selection}
                  onClick={() => this.addVariable()}
                >
                  Add Variable
                </button>
              </div>
              <div className="mt-1 text-sm">
                Prompt Variables:
                {this.tool.prompts[this.currentPrompt].variables.length ? (
                  <div className="flex flex-row">{Variables}</div>
                ) : (
                  <div className="text-red-500">No variables yet</div>
                )}
              </div>
              <Divider />

              <div className="flex flex-row justify-between">
                <div
                  className={`${
                    canChangePrompts
                      ? "text-blue-400 hover:text-blue-500 cursor-pointer"
                      : "text-gray-400 cursor-default"
                  }`}
                  onClick={() => this.incrementPrompt(false)}
                >
                  {"< Previous"}
                </div>
                <div>{`${this.currentPrompt + 1}/${
                  this.tool.prompts.length
                }`}</div>
                <div
                  className={`${
                    canChangePrompts
                      ? "text-blue-400 hover:text-blue-500 cursor-pointer"
                      : "text-gray-400 cursor-default"
                  }`}
                  onClick={() => this.incrementPrompt()}
                >
                  {"Next >"}
                </div>
              </div>

              <br />
              <div className="flex flex-row justify-between">
                <button
                  className="hover:bg-green-600 bg-green-500 font-medium rounded-lg text-md px-4 py-2 bg-gray-200 text-white mt-4 border border-gray-300 inline-block"
                  onClick={() => this.addUIElement()}
                >
                  Add UI element
                </button>
              </div>
              <div
                onBlur={() => {
                  this.autoFocus = "";
                }}
              >
                UI Structure:
                {!this.tool.uiStructure.length ? "No UI elements yet" : ""}
                {this.tool.uiStructure.length ? (
                  <UIElements
                    uiStructure={this.tool.uiStructure}
                    variables={this.combinedVariables}
                    promptKeys={this.promptKeys}
                    availableUiTypes={this.availableUITypes}
                    defaultUIType={this.defaultUIType}
                    changeUIElementType={this.changeUIElementType}
                    changeUIElementValue={this.changeUIElementValue}
                    changeUIElementText={this.changeUIElementText}
                    changeUIElementTitle={this.changeUIElementTitle}
                    changeUIElementOptions={this.changeUIElementOptions}
                    changeUIMinMaxOptions={this.changeUIMinMaxOptions}
                    changeUIElementCondition={this.changeUIElementCondition}
                    changeOutputType={this.changeOutputType}
                    removeUIElement={this.removeUIElement}
                    onSortEnd={this.onSortEnd}
                    autoFocus={this.autoFocus}
                  />
                ) : null}
              </div>

              <div className="text-red-500 h-1 mt-3">{this.error || ""}</div>
              <button
                className="hover:bg-green-600 bg-green-500 font-medium rounded-lg text-md px-4 py-2 bg-gray-200 text-white mt-4 border border-gray-300 inline-block"
                onClick={() => this.createOrEditTool()}
              >
                {`${this.editMode ? "Save" : "Create"} Tool`}
              </button>
            </div>
            <div className="flex flex-1 flex-col">
              <Title title="UI Preview" />
              <Preview
                tool={this.tool}
                updateHistory={() => this.updateHistory()}
              />
            </div>
          </Grid>
          <div className="py-10">
            <History tool={this.tool} revertTool={this.revertTool} />
          </div>
        </MainBody>
        {this.showTestModal && (
          <Test
            showTestModal={this.showTestModal}
            changeShowTestModal={() => this.changeShowTestModal(false)}
            pickedPolishedPrompt={this.pickedPolishedPrompt}
            prompt={this.tool.prompts[this.currentPrompt].prompt}
            variables={this.tool.prompts[this.currentPrompt].variables}
            role={this.tool.prompts[this.currentPrompt].role}
          />
        )}
      </div>
    );
  }
}

const Preview = observer(({ tool, updateHistory }) => (
  <ToolPreview tool={tool} updateHistory={updateHistory} />
));
const History = observer(({ tool, revertTool }) => (
  <ToolHistory historyId={tool.historyId} tool={tool} revertTool={revertTool} />
));

const Test = observer(
  ({
    pickedPolishedPrompt,
    showTestModal,
    changeShowTestModal,
    prompt,
    variables,
    role,
  }) => (
    <TestModal
      pickedPolishedPrompt={pickedPolishedPrompt}
      showModal={showTestModal}
      setShowModal={changeShowTestModal}
      initialPrompt={prompt}
      initialVariables={variables}
      initialRole={role}
    />
  )
);

function Grid({ children }) {
  return (
    <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-2 ">
      {children}
    </div>
  );
}
const HighLightArea = observer(({ prompt, onChangePrompt, variables }) => (
  <HighlightWithinTextarea
    value={prompt || ""}
    onChange={onChangePrompt}
    highlight={variables}
    placeholder=""
  />
));
const UIElements = observer(
  ({
    uiStructure,
    variables,
    promptKeys,
    availableUiTypes,
    changeUIElementType,
    changeUIElementValue,
    changeUIElementText,
    changeUIElementTitle,
    changeUIElementOptions,
    changeUIMinMaxOptions,
    changeUIElementCondition,
    removeUIElement,
    onSortEnd,
    autoFocus,
  }) => (
    <SortableList
      onSortEnd={onSortEnd}
      items={uiStructure.map((el, idx) => (
        <div
          key={`${Math.random()}`}
          className="py-1 flex flex-row items-center flex-1 w-full"
        >
          {idx + 1}.
          <select
            id={`select-${idx}`}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
            value={el.uiType}
            onChange={e => changeUIElementType(e.target.value, idx)}
          >
            {availableUiTypes.map(({ key, text }) => (
              <option key={key} value={key}>
                {text}
              </option>
            ))}
          </select>
          {variables.length &&
          (checkIfValue(el.uiType) ||
            checkIfOutputValue(el.uiType) ||
            checkIfSelect(el.uiType) ||
            checkIfNumberValue(el.uiType) ||
            checkIfOutputSelect(el.uiType) ||
            checkIfOutputNumberValue(el.uiType)) ? (
            <>
              <select
                id={`select-value-${idx}`}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                value={
                  variables.find(v => v.text === el.uiValue)?.text ||
                  variables.find(v => v.promptVariable === el.uiValue)?.text ||
                  variables[0].text
                }
                onChange={e => changeUIElementValue(e.target.value, idx)}
              >
                {variables.map(val => (
                  <option key={Math.random()} value={val.text}>
                    {val.text}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id={`text-label-${idx}`}
                className="block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                onChange={e => changeUIElementTitle(e.target.value, idx)}
                autoFocus={el.key === autoFocus}
                defaultValue={el.title}
                placeholder="Title..."
              />
            </>
          ) : !variables.length &&
            (checkIfValue(el.uiType) || checkIfOutputValue(el.uiType)) ? (
            <div className="text-red-500 mx-2"> No Variables defined yet. </div>
          ) : null}
          {checkIfSelect(el.uiType) || checkIfOutputSelect(el.uiType) ? (
            <>
              <input
                type="text"
                id={`text-options-${idx}`}
                className="block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                onChange={e => changeUIElementOptions(e.target.value, idx)}
                autoFocus={`${el.key}-options` === autoFocus}
                value={el.uiOptions}
                placeholder="Options..."
              />
            </>
          ) : null}
          {checkIfNumberValue(el.uiType) ||
          checkIfOutputNumberValue(el.uiType) ? (
            <>
              <input
                type="number"
                id={`text-min-${idx}`}
                className="block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                onChange={e =>
                  changeUIMinMaxOptions(e.target.value, idx, "min")
                }
                autoFocus={`${el.key}-min` === autoFocus}
                value={el.min}
                min="0"
                placeholder="Min..."
              />
              <input
                type="number"
                id={`text-max-${idx}`}
                className="block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                onChange={e =>
                  changeUIMinMaxOptions(e.target.value, idx, "max")
                }
                autoFocus={`${el.key}-max` === autoFocus}
                value={el.max}
                min="0"
                placeholder="Max..."
              />
            </>
          ) : null}
          {checkIfHeading(el.uiType) ? (
            <input
              type="text"
              id={`text-input-${idx}`}
              className="w-full block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
              onChange={e => changeUIElementText(e.target.value, idx)}
              value={el.uiText}
              placeholder="Static text..."
              autoFocus={el.key === autoFocus}
            />
          ) : null}
          {promptKeys.length &&
          (checkIfSubmit(el.uiType) || checkIfOutputSubmit(el.uiType)) ? (
            <>
              <select
                id={`select-submit-${idx}`}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                value={el.uiValue || promptKeys[0]}
                onChange={e => changeUIElementValue(e.target.value, idx)}
              >
                {promptKeys.map(val => (
                  <option key={Math.random()} value={val}>
                    {val}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id={`text-label-${idx}`}
                className="block text-md mx-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                onChange={e => changeUIElementTitle(e.target.value, idx)}
                autoFocus={el.key === autoFocus}
                value={el.title}
                placeholder="Title..."
              />
            </>
          ) : null}
          {variables.length && checkIfOutputAsValue(el.uiType) ? (
            <>
              <select
                id={`select-value-${idx}`}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                value={
                  variables.find(v => v.text === el.uiValue)?.text ||
                  variables.find(v => v.promptVariable === el.uiValue)?.text ||
                  variables[0].text
                }
                onChange={e => changeUIElementValue(e.target.value, idx)}
              >
                {variables.map(val => (
                  <option key={Math.random()} value={val.text}>
                    {val.text}
                  </option>
                ))}
              </select>
            </>
          ) : !variables.length && checkIfOutputAsValue(el.uiType) ? (
            <div className="text-red-500 mx-2"> No Variables defined yet. </div>
          ) : null}
          {checkIfConditionalOutput(el.uiType) ? (
            <select
              id={`select-condition-${idx}`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
              value={el.uiValue || promptKeys[0]}
              onChange={e => changeUIElementValue(e.target.value, idx)}
            >
              {promptKeys.map(val => (
                <option key={Math.random()} value={val}>
                  {val}
                </option>
              ))}
            </select>
          ) : null}
          {checkIfOutputs(el.uiType) ? (
            <select
              id={`select-condition-${idx}`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block mx-2 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
              value={el.condition || promptKeys[0]}
              onChange={e => changeUIElementCondition(e.target.value, idx)}
            >
              {promptKeys.map(val => (
                <option key={Math.random()} value={val}>
                  {val}
                </option>
              ))}
            </select>
          ) : null}
          <button
            className="hover:bg-red-600 bg-red-500 font-medium rounded-md text-sm px-1 py-1 bg-gray-200 text-white border border-gray-300 inline-block"
            onClick={() => removeUIElement(el.key)}
          >
            X
          </button>
        </div>
      ))}
    />
  )
);

const SortableItem = SortableElement(({ value }) => <>{value}</>);
const SortableList = SortableContainer(({ items }) => (
  <ol>
    {items.map((value, index) => (
      <SortableItem key={`item-${Math.random()}`} index={index} value={value} />
    ))}
  </ol>
));

export default withRouter(Body);
