import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";

@observer
class Autocomplete extends Component {
  @observable filteredSuggestions = [];
  @observable showSuggestions = false;
  @observable userInput = this.props.initialValue;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.userInput = props.initialValue;
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.filteredSuggestions = filteredSuggestions;
    this.showSuggestions = true;
    this.userInput = userInput;

    if (this.props.inputCb) {
      this.props.inputCb(this.userInput);
    }
  };

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.filteredSuggestions = [];
    this.showSuggestions = false;
    this.userInput = e.currentTarget.innerText;

    if (this.props.inputCb) {
      this.props.inputCb(this.userInput);
    }
  };

  render() {
    const {
      onChange,
      onClick,
      filteredSuggestions,
      showSuggestions,
      userInput,
    } = this;

    let suggestionsListComponent = null;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="absolute w-full z-30">
            {filteredSuggestions.map(suggestion => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                className="w-full block text-md text-gray-900 border border-gray-100 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                key={suggestion}
                onMouseDown={onClick}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        );
      }
    }

    return (
      <>
        <input
          type="text"
          onChange={onChange}
          onFocus={() => (this.showSuggestions = true)}
          onBlur={() => (this.showSuggestions = false)}
          value={userInput}
          className="block text-lg text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <div className="relative">{suggestionsListComponent}</div>
      </>
    );
  }
}

export default Autocomplete;
