/* eslint-disable global-require */
import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { computed, observable, makeObservable } from "mobx";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { DatabaseIcon, UserCircleIcon } from "@heroicons/react/outline";

import { IconDashboard, IconPlus, IconFeatures } from "./Icons";

import Body from "./Components/Body";

function HeaderExpand(props) {
  const location = useLocation();
  return (
    <SuperHeader active={location.pathname === "/"}>
      {props.children}
    </SuperHeader>
  );
}

@inject("store")
@observer
class SidebarCompontent extends Component {
  @observable isChatPath = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }
  componentDidUpdate() {
    const aiPaths = this.props.location.pathname.split("/");
    this.isChatPath = aiPaths[1] === "tools-chat";
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }

  onKeyUp = e => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }
    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  @computed get fromColor() {
    if (this.props.store.profile.credits <= 0) {
      return "bg-red-200 text-red-600";
    }
    if (this.props.store.profile.status === "trialing") {
      return "";
    }
    if (this.props.store.profile.status === "active") {
      return "";
    }
    if (this.props.store.profile.status === "incomplete") {
      return "";
    }
    return "bg-red-200 text-red-600";
  }

  shortcutHandler = e => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      // select all text in input with id q
      document.getElementById("q").focus();
      document.getElementById("q").select();
    }
  };

  render() {
    if (this.isChatPath) return null;
    return (
      <>
        <Textarea
          readOnly
          name="copy-textarea"
          id="copy-textarea"
          value={this.props.store.copyToClipboardText}
        />
        <HeaderExpand>
          <Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
            <div className="mr-4">
              <NavLink to="/">
                <Logo />
              </NavLink>
            </div>
            <div>
              <div className="flex">
                <div
                  className={`items-center flex inline-flex ${
                    this.props.store.profile.credits
                      ? " bg-gray-100 text-gray-500"
                      : " bg-red-100 text-red-500"
                  } text-sm rounded-md px-3 py-1 font-medium my-2 mr-2`}
                >
                  <DatabaseIcon className="w-4 h-4 mr-2" />
                  {this.props.store.profile.credits}&nbsp;
                  <span className="hidden lg:block">credits remain</span>
                </div>
              </div>
            </div>
          </Body>
        </HeaderExpand>
        <div className="border-b border-gray-300 bg-white shadow-sm ">
          <div className="container flex mx-auto px-4 md:px-28 flex select-none">
            <NavLink
              to="/"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Tools</div>
            </NavLink>

            <NavLink
              to="/my-tools"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <IconFeatures className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">My Tools</div>
            </NavLink>

            {this.props.store.isCreator && (
              <NavLink
                to="/new-tool"
                exact
                tabIndex={0}
                onClick={() => (this.props.store.toolsKeyword = "")}
                activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
                className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <IconPlus className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block">Create Tool</div>
              </NavLink>
            )}

            <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
              <label
                htmlFor="q"
                className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 "
              >
                <div
                  type="submit"
                  className="p-2 focus:outline-none focus:shadow-outline "
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 transition"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
              </label>
              <Input
                type="search"
                tabIndex={-1}
                id="q"
                name="q"
                className="py-4 pl-4 md:pl-14 text-xl focus:outline-none focus:bg-white focus:text-gray-900 transition flex flex-1 w-full"
                placeholder="Search...  [Shortcut: Ctrl + K]"
                autoComplete="off"
                value={this.props.store.toolsKeyword}
                onChange={this.props.store.onChangeToolsKeyword}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <NavLink
              to="/my-profile"
              exact
              tabIndex="-1"
              activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
              className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}
            >
              <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block"> My Profile</div>
            </NavLink>
          </div>
        </div>
        {this.props.children}
      </>
    );
  }
}

const Logo = () => (
  <svg
    width="400"
    height="50"
    viewBox="0 0 812 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25013 14.8778C16.7067 43.3814 40.3763 67.051 68.8799 83.5076C97.3835 99.9641 129.717 108.628 162.63 108.628C195.543 108.628 227.876 99.9641 256.38 83.5075C284.883 67.051 308.553 43.3814 325.01 14.8778L300.693 0.838781C286.701 25.074 266.576 45.1991 242.341 59.1913C218.106 73.1835 190.614 80.5498 162.63 80.5498C134.645 80.5498 107.154 73.1835 82.9189 59.1913C58.6837 45.1991 38.5586 25.074 24.5664 0.838826L0.25013 14.8778Z"
      fill="#00054A"
    />
    <path
      d="M341.63 46.1278C341.63 62.7038 348.215 78.6009 359.936 90.322C371.657 102.043 387.554 108.628 404.13 108.628C420.706 108.628 436.603 102.043 448.324 90.322C460.045 78.601 466.63 62.7038 466.63 46.1278L441.63 46.1278C441.63 56.0734 437.679 65.6117 430.646 72.6443C423.614 79.6769 414.075 83.6278 404.13 83.6278C394.184 83.6278 384.646 79.6769 377.613 72.6443C370.581 65.6117 366.63 56.0734 366.63 46.1278L341.63 46.1278Z"
      fill="#00054A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M791.797 54.2589C791.797 54.2589 791.794 54.2796 791.781 54.3205C791.789 54.2792 791.797 54.2589 791.797 54.2589ZM786.583 59.9091C790.172 57.1247 790.172 52.3419 786.583 49.5575C780.776 45.0524 771.123 40.1279 757.407 35.6272C730.191 26.697 691.331 20.8387 647.5 20.8387C603.669 20.8387 564.809 26.697 537.593 35.6272C523.877 40.1279 514.224 45.0524 508.417 49.5575C504.827 52.3419 504.827 57.1247 508.417 59.9091C514.224 64.4142 523.877 69.3387 537.593 73.8393C564.809 82.7695 603.669 88.6278 647.5 88.6278C691.331 88.6278 730.191 82.7695 757.407 73.8393C771.123 69.3387 780.776 64.4142 786.583 59.9091ZM503.203 54.2589C503.203 54.2589 503.211 54.2792 503.218 54.3205C503.205 54.2796 503.203 54.2589 503.203 54.2589ZM503.203 55.2077C503.203 55.2077 503.205 55.1869 503.218 55.146C503.211 55.1873 503.203 55.2077 503.203 55.2077ZM791.781 55.1461C791.794 55.1869 791.797 55.2077 791.797 55.2077C791.797 55.2077 791.789 55.1874 791.781 55.1461ZM647.5 108.628C738.213 108.628 811.75 84.4984 811.75 54.7333C811.75 24.9681 738.213 0.838745 647.5 0.838745C556.787 0.838745 483.25 24.9681 483.25 54.7333C483.25 84.4984 556.787 108.628 647.5 108.628Z"
      fill="#00054A"
    />
  </svg>
);

const Input = styled.input``;

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

// transition:ease-in-out 0.5s margin-top;
// 	transition-delay:0.05s;
const SuperHeader = styled.div`
  height: 150px;
  background: white;
  margin-top: ${props => (props.active ? "0px" : "-150px")};
  display: ${props => (props.hidden ? "hidden" : "flex")};
  background-size: auto 50%;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
`;

export default withRouter(SidebarCompontent);
