import React, { Component } from "react";
import { observable, computed, makeObservable } from "mobx";
import { Helmet } from "react-helmet-async";
import { observer, inject } from "mobx-react";

import { Title, Divider, Grid, Tool } from "./Dashboard";
import MainBody from "./Components/Body";
import BasicModal from "./Components/BasicModal";

@inject("store")
@observer
class Body extends Component {
  @observable showDeleteModal;
  @observable toDeleteToolId;

  constructor(props) {
    super(props);
    makeObservable(this);
  }
  @computed get permissions() {
    return this.props.store.myTools.filter(tool =>
      tool.permissions.some(r =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }
  @computed get bookmarksPermissions() {
    return this.props.store.bookmarks.filter(tool =>
      tool.permissions.some(r =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }

  toggleShowDeleteTool = (val = null, toolId = null) => {
    if (val !== null) {
      this.showDeleteModal = !this.showDeleteModal;
    } else {
      this.showDeleteModal = val;
    }
    this.toDeleteToolId = toolId;
  };

  async deleteTool() {
    await this.props.store.api.delete(`/tool/${this.toDeleteToolId}`);
    await this.props.store.init();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Tools - My tools</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          {this.props.store.isCreator && this.permissions.length ? (
            <>
              <Title title="My Tools" />
              <Grid>
                {this.permissions.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    uses={tool.uses}
                    createdBy={tool.createdBy}
                    toolId={tool.id}
                    searchKey={tool.searchKey}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    canEdit={tool.apiCreated && tool.canEdit}
                    setShowModal={this.toggleShowDeleteTool}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}
          {this.bookmarksPermissions.length ? (
            <>
              <Title title="My Bookmarks" />
              <Grid>
                {this.bookmarksPermissions.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    uses={tool.uses}
                    createdBy={tool.createdBy}
                    toolId={tool.id}
                    searchKey={tool.searchKey}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    canEdit={tool.apiCreated && tool.canEdit}
                    setShowModal={this.toggleShowDeleteTool}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}
          {!this.bookmarksPermissions.length ? (
            <>
              <Title title="My Bookmarks" />
              <div>No bookmarks yet...</div>
              <Divider />
            </>
          ) : null}
        </MainBody>
        <Modal
          showModal={this.showDeleteModal}
          setShowModal={(val, id) => this.toggleShowDeleteTool(val, id)}
          action={() => this.deleteTool()}
        />
      </>
    );
  }
}

const Modal = observer(({ showModal, setShowModal, action }) => (
  <BasicModal
    title="Are you sure you want to delete the tool?"
    showModal={showModal}
    setShowModal={setShowModal}
    action={action}
    yesText="Delete"
    noText="Cancel"
  />
));

export default Body;
