const { v4: uuidv4 } = require("uuid");
const {
  ToolType,
  ToolCategories,
  DefaultModel,
} = require("../../NewTool/helper");

class NewTool {
  toolName = "";
  toolCategory = "";
  toolType = "";
  introMessage = "";
  prompts = [];
  uiStructure = [];
  searchKey = "";
  historyId = "";
  userId = "";
  createdBy = "";
  historyVersion = 0;

  constructor(
    toolName,
    toolCategory,
    toolType,
    introMessage,
    prompts,
    uiStructure,
    searchKey,
    historyId,
    userId,
    createdBy,
  ) {
    this.toolName = toolName;
    this.toolCategory = toolCategory;
    this.toolType = toolType;
    this.introMessage = introMessage;
    this.prompts = prompts;
    this.uiStructure = uiStructure;
    this.searchKey = searchKey;
    this.historyId = historyId;
    this.userId = userId;
    this.createdBy = createdBy
  }

  static fromTool(tool) {
    const prompts = Object.keys(tool.aiPrompts);
    const uiStructure = tool.prompts[0].prompts.map(el => ({
      uiType: el.type,
      uiValue: el.attr || "",
      key: Math.random(),
      uiText: el.value || "",
      title: el.title || "",
      uiOptions: el.options.map(e => e.value).join(","),
      min: el.min,
      max: el.max,
      condition: el.condition || prompts[0],
    }));
    const historyId = tool.historyId || uuidv4();

    return new NewTool(
      tool.title,
      tool.category,
      tool.type,
      tool.introMessage,
      Object.values(tool.aiPrompts),
      uiStructure,
      tool.searchKey,
      historyId,
      tool.userId,
      tool.createdBy,
    );
  }

  static init() {
    const historyId = uuidv4();
    return new NewTool(
      "",
      ToolCategories[0],
      ToolType.public.key,
      "",
      [
        {
          key: "default",
          model: DefaultModel,
          variables: [],
        },
      ],
      [],
      "",
      historyId,
      "",
      ""
    );
  }

  static toAPIJson(tool) {
    return {
      title: tool.toolName,
      type: tool.toolType,
      introMessage: tool.introMessage,
      prompts: tool.prompts,
      uiStructure: tool.uiStructure,
      searchKey: tool.searchKey,
      historyId: tool.historyId,
      userId: tool.userId,
    };
  }
}

export default NewTool;
