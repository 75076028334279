import React, { Component } from "react";
import { computed, makeObservable, observable } from "mobx";
import { observer, inject } from "mobx-react";
import TextareaAutosize from "react-textarea-autosize";
import { CheckIcon } from "@heroicons/react/outline";

import CodeEditor from "@uiw/react-textarea-code-editor";
import EntryDropdown from "./EntryDropdown";

import {
  checkIfConditionalOutput,
  checkIfHeading,
  checkIfNumberValue,
  checkIfOutputNumberValue,
  checkIfOutputSelect,
  checkIfSelect,
} from "../NewTool/helper";

@inject("store")
@observer
class EntryInput extends Component {
  @observable promptLocal;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (this.props.updateValue) this.promptLocal = observer(this.props.prompt);
  }

  @computed get prompt() {
    return this.props.prompt;
  }

  onChange = async e => {
    if (this.props.updateValue) {
      this.promptLocal.value = e.target.value;
      this.props.updateValue(this.props.prompt.attr, e.target.value);
    } else {
      this.props.prompt.value = e.target.value;
    }
    this.prompt.error = "";
  };

  onChangeDropdown = async e => {
    this.props.prompt.value = e;
    if (this.props.updateValue) {
      this.props.updateValue(this.props.prompt.attr, e);
    }
    this.props.prompt.error = "";
  };

  // handleEditorChange = (e, value) => {
  // 	console.log(e)
  // 	this.props.prompt.value = e.target.value
  // 	this.props.prompt.error = ""
  // };

  @computed get isMinLength() {
    const usedPrompt = this.props.updateValue
      ? this.promptLocal
      : this.props.prompt;

    if (!usedPrompt.min) {
      return false;
    }
    if (!usedPrompt.type === "number" || !usedPrompt.type === "output-number") {
      return false;
    }
    if (usedPrompt.value.length === 0) {
      return false;
    }
    if (usedPrompt.value.length < usedPrompt.min) {
      return true;
    }
    return false;
  }

  @computed get isLongEnoughToDrop() {
    return this.props.updateValue
      ? this.promptLocal.value < 30
      : this.props.prompt.value.length < 30;
  }

  @computed get currentNumberOfCharacters() {
    const usedPrompt = this.props.updateValue
      ? this.promptLocal
      : this.props.prompt;

    if (!usedPrompt || !usedPrompt.value) return "";
    const currentCharacters = usedPrompt.value.length;
    let maxCharacters = 80;
    if (usedPrompt.type === "textarea") {
      maxCharacters = 400;
    }
    if (usedPrompt.maxLength) {
      maxCharacters = usedPrompt.maxLength;
    }
    if (usedPrompt.max) {
      maxCharacters = usedPrompt.max;
    }

    return `${currentCharacters}/${maxCharacters}`;
  }

  render() {
    const { Icon } = this.props;
    if (this.props.updateValue && !this.promptLocal) return null;

    const options = !Array.isArray(this.props.prompt?.options)
      ? this.props.prompt?.options
          ?.split(",")
          .map(opt => ({ value: opt, label: opt }))
      : this.props.prompt?.options?.map(e => ({
          value: e.value,
          label: e.value,
        }));

    return (
      <div className={`${this.props.size ? "mt-4" : "mt-4"}`}>
        {this.props.prompt.title && !checkIfHeading(this.props.prompt.type) && (
          <label
            htmlFor={this.props.prompt.attr}
            className="text-gray-600 font-medium text-md"
          >
            {this.props.prompt.title}
          </label>
        )}

        <div className="relative flex flex-col">
          {checkIfSelect(this.props.prompt.type) ||
          checkIfNumberValue(this.props.prompt.type) ||
          checkIfOutputSelect(this.props.prompt.type) ||
          checkIfOutputNumberValue(this.props.prompt.type) ||
          checkIfConditionalOutput(this.props.prompt.type) ||
          !this.currentNumberOfCharacters ? null : (
            <div
              className={`text-xs absolute z-30 right-2 select-none pointer-events-none transition-all top bg-white px-2 ${
                this.isMinLength ? "text-red-500" : "text-gray-400"
              } ${!this.isLongEnoughToDrop && this.props.size ? "" : ""}`}
            >
              {this.currentNumberOfCharacters} chars
            </div>
          )}

          <label
            htmlFor={this.props.prompt.attr}
            className="relative transition text-gray-600 focus-within:text-gray-800 block"
          >
            {Icon ? (
              <Icon
                className={`
							${this.props.size ? "" : "h-6 w-6 top-5 left-2"} 
							${this.props.size === "sm" && "h-4 w-4 top-5 left-2"} 
							${this.props.size === "lg" && "h-8 w-8 top-5 left-2"} 
							transition absolute  select-none pointer-events-none opacity-75`}
              />
            ) : null}
            {this.props.prompt.type ? null : (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 80}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`outline-none focus:outline-none text-${
                  this.props.size || "lg"
                } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400 ${
                  this.props.prompt.error ? "border-red-400" : "border-gray-300"
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
								${this.props.size ? "" : "pl-9"}
								${this.props.size === "sm" && "pl-7"}
								${this.props.size === "lg" && "pl-12"}
								`
                } `}
                onChange={this.onChange}
              />
            )}
            {(this.props.prompt.type === "text" ||
              this.props.prompt.type === "output-text") && (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 400}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`outline-none focus:outline-none text-${
                  this.props.size || "lg"
                } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${
                  this.props.prompt.error ? "border-red-400" : "border-gray-300"
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
							      ${this.props.size ? "" : "pl-7"}
							      ${this.props.size === "sm" && "pl-7"}
							      ${this.props.size === "lg" && "pl-12"}
							    `
                }`}
                onChange={this.onChange}
                value={this.props.prompt.value}
              />
            )}
            {(this.props.prompt.type === "number" ||
              this.props.prompt.type === "output-number") && (
              <>
                {this.props.prompt.min && this.props.prompt.max ? (
                  <>
                    <input
                      name={this.props.prompt.attr}
                      id={this.props.prompt.attr}
                      maxLength={this.props.prompt.maxLength || 100}
                      autoFocus={this.props.index === 0}
                      className={`outline-none focus:outline-none text-${
                        this.props.size || "lg"
                      } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${
                        this.props.prompt.error
                          ? "border-red-400"
                          : "border-gray-300"
                      } font-regular mt-2 transition-all ${
                        Icon &&
                        `${this.props.size ? "" : "pl-7"}
							            ${this.props.size === "sm" && "pl-7"}
							            ${this.props.size === "lg" && "pl-12"}
							          `
                      }`}
                      onChange={this.onChange}
                      value={this.props.prompt.value || this.props.prompt.min}
                      type="range"
                      min={this.props.prompt.min}
                      max={this.props.prompt.max}
                    />
                    <div className="-mt-7 py-2 flex w-full justify-between">
                      <span className="text-sm text-gray-600">
                        {this.props.prompt.min}
                      </span>
                      <span className="text-sm text-gray-600">
                        {this.props.prompt.max}
                      </span>
                    </div>{" "}
                  </>
                ) : (
                  <input
                    name={this.props.prompt.attr}
                    id={this.props.prompt.attr}
                    autoFocus={this.props.index === 0}
                    type="number"
                    className={`outline-none focus:outline-none text-${
                      this.props.size || "lg"
                    } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${
                      this.props.prompt.error
                        ? "border-red-400"
                        : "border-gray-300"
                    } font-regular mt-2 transition-all ${
                      Icon &&
                      `
							      ${this.props.size ? "" : "pl-7"}
							      ${this.props.size === "sm" && "pl-7"}
							      ${this.props.size === "lg" && "pl-12"}
							    `
                    }`}
                    onChange={this.onChange}
                    value={this.props.prompt.value}
                  />
                )}
              </>
            )}
          </label>

          {(this.props.prompt.type === "dropdown" ||
            this.props.prompt.type === "single-select" ||
            this.props.prompt.type === "multiple-select" ||
            this.props.prompt.type === "output-single-select" ||
            this.props.prompt.type === "output-multiple-select") && (
            <EntryDropdown
              onChange={this.onChangeDropdown}
              options={options}
              value={this.props.prompt.value}
              isMulti={
                this.props.prompt.type === "multiple-select" ||
                this.props.prompt.type === "output-multiple-select"
              }
            />
          )}

          {(this.props.prompt.type === "code" ||
            this.props.prompt.type === "output-code") && (
            <div className="mt-4 -mx-6" style={{ backgroundColor: "#f5f5f5" }}>
              <CodeEditor
                // height="350px"
                style={{
                  fontFamily: "JetBrains Mono",
                  fontSize: "1rem",
                }}
                padding={30}
                language={this.props.language}
                onChange={this.onChange}
                autoFocus={this.props.index === 0}
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                {...this.props.prompt}
              />
            </div>
          )}

          {(this.props.prompt.type === "textarea" ||
            this.props.prompt.type === "output-textarea") && (
            <TextareaAutosize
              autoFocus={this.props.index === 0}
              minRows={this.props.minRows || 2}
              maxRows={10}
              name={this.props.prompt.attr}
              id={this.props.prompt.attr}
              {...this.props.prompt}
              className={`outline-none focus:outline-none text-${
                this.props.size || "lg"
              } bg-white rounded-md ${
                this.props.size ? "px-2 py-2" : "px-4 py-4"
              }  min-w-full border border-gray-300 font-regular focus:border-gray-400 ${
                this.props.prompt.error ? "border-red-400" : "border-gray-300"
              } font-regular mt-2 `}
              onChange={this.onChange}
            />
          )}

          {this.props.prompt.type === "radio" && this.props.prompt.options && (
            <>
              <div className="mt-1">
                {this.props.prompt.options.map((option, index) => (
                  <Option
                    key={index}
                    Icon={option.Icon}
                    title={option.title}
                    desc={option.desc}
                    active={option.value === this.props.prompt.value}
                    onClick={() => (this.props.prompt.value = option.value)}
                  />
                ))}
              </div>
            </>
          )}

          {this.props.prompt.type === "h1" && (
            <h1 className="text-5xl font-extrabold dark:text-white">
              {this.props.prompt.value}
            </h1>
          )}

          {this.props.prompt.type === "h2" && (
            <h2 className="text-4xl font-bold dark:text-white">
              {this.props.prompt.value}
            </h2>
          )}

          {this.props.prompt.type === "h3" && (
            <h3 className="text-3xl font-bold dark:text-white">
              {this.props.prompt.value}
            </h3>
          )}
        </div>

        {(this.props.prompt.label || this.props.prompt.error) && (
          <div
            htmlFor={this.props.prompt.attr}
            className={`${
              this.props.prompt.error ? "text-red-400" : "text-gray-400"
            } text-xs transition-all line mt-1`}
          >
            {this.props.prompt.error || this.props.prompt.label}
          </div>
        )}
      </div>
    );
  }
}

function Option({ _index, title, desc, active, onClick, Icon }) {
  return (
    <div
      className={`select-none flex w-full text-left items-center font-medium py-1 px-2 hover:bg-${
        active ? "green" : "gray"
      }-200 bg-${
        active ? "green" : "na"
      }-100 rounded-md cursor-pointer transition`}
      onClick={onClick}
    >
      <div
        className={`flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-${
          active ? "green-500" : "gray-300"
        } mr-4 transition`}
      >
        {Icon ? (
          <Icon
            className={`transition h-4 w-4 text-${
              active ? "white" : "gray-400"
            }`}
            aria-hidden="true"
          />
        ) : (
          <CheckIcon
            className={`transition h-4 w-4 text-${
              active ? "white" : "gray-400"
            }`}
            aria-hidden="true"
          />
        )}
      </div>
      <div>
        {title}
        <div className="font-normal text-sm">{desc}</div>
      </div>
    </div>
  );
}

export default EntryInput;

// export const MultipleOption = observer(({ title, value, onChange, placeholder, examples, index, ...props }) => {
// 	return (
// 	   <div className="mt-6">
// 			<label htmlFor={title} className="text-gray-600 font-medium text-md">{title}</label>
// 			{props.type === "textarea" ? <textarea
// 			  name={title}
// 			  id={title}
// 			  {...props}
// 			  className="focus:outline-none text-lg bg-white rounded-md px-4 py-2  min-w-full border border-gray-300 font-regular mt-2"
// 			  value={value}
// 			  onChange={e=>onChange(e.target.value,index)}
// 			  placeholder={placeholder} /> : <input
// 			  name={title}
// 			  id={title}
// 			  {...props}
// 			  className="focus:outline-none text-lg bg-white rounded-md px-4 py-2  min-w-full border border-gray-300 font-regular mt-2"
// 			  value={value}
// 			  onChange={e=>onChange(e.target.value,index)}
// 			  placeholder={placeholder}  />}
// 			{examples && <label htmlFor={title} className="text-gray-400 text-xs">{examples}</label>}
// 		  </div>
// 	  )
//   })
