import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet-async";

import {
  PaperAirplaneIcon,
  SparklesIcon,
  UserIcon,
  PencilAltIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/solid";
import { checkIfValue } from "../NewTool/helper";
import Models from "../Models";
import TypistWrapper from "../Components/TypistWrapper";
import config from "../config";
import { IconRefresh } from "../Icons";
import { Shortcut, Tooltip } from "../Components/Output";
import SmallPopup from "../Components/SmallPopup";
import LockedScreen from "./LockedScreen";

const { v4: uuidv4 } = require("uuid");

const { Tool } = Models;

@inject("store")
@observer
class SharedToolChat extends Component {
  @observable tokenId = "";
  @observable tool = {};
  @observable.deep prompts = [];

  @observable history = {};
  @observable loading = false;

  editRef = createRef();
  endRef;

  @observable input = "";
  @observable messages = [];
  @observable dissapearingMessages = [];
  @observable prevMessages = [];

  @observable isWarningOpen = false;

  @observable lastMessage;
  @observable errorMessage;
  @observable showRetry;

  @observable lastPostObj;
  @observable editingMessage = -1;
  editedMessage = "";

  shouldLock = false;
  @observable isLocked = false;
  @observable shouldShowLockScreen = false;

  @observable isDarkMode = false;
  @observable shouldHaveDissapearingMessages = false;
  @observable introMessage = "";

  toolSession = "";

  ws = createRef();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount(_prevProps, _prevState, _snapshot) {
    const pathName = this.props.location.pathname;
    this.tokenId = pathName.substring(pathName.lastIndexOf("/") + 1);

    const tool = await this.props.store.api
      .get(`/public-tools/get-tool/${this.tokenId}`)
      .then(({ data }) => data);
    this.tool = Tool.fromJSON(tool);
    this.prompts = [...this.tool.prompts];
    this.scrollToBottom();

    if (this.tool.isLocked && this.tool.enableOneIpSession) {
      this.isLocked = true;
      this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
      if (!this.shouldShowLockScreen)
        alert(
          tool.customMessages?.lockedMessage || "Chat session already used"
        );
    } else if (this.tool.enableOneSession) {
      if (this.props.store.isLocked(this.tool.id)) {
        this.isLocked = true;
        this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
        if (!this.shouldShowLockScreen)
          alert(
            tool.customMessages?.lockedMessage || "Chat session already used"
          );
      } else {
        this.shouldLock = true;
      }
    }

    this.isDarkMode = this.tool.options?.darkMode;
    this.shouldHaveDissapearingMessages =
      this.tool.options?.dissapearingMessages;
    this.introMessage = this.tool.introMessage;
    this.toolSession = uuidv4();

    this.scrollToBottomFnc();
  }

  componentWillUnmount() {
    if (this.ws.current) this.ws.current.close();
  }

  handleKeyPress = async event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
    if (this.loading || this.input.trim().length === 0 || this.isLocked) return;
    if (event.key === "Enter" && !event.shiftKey) {
      await this.handleSubmit();
    }
  };

  handleMessageEditPress = async event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
    if (event.key === "Enter" && !event.shiftKey) {
      this.prevMessages = this.messages;
      this.messages = this.messages.slice(0, this.editingMessage);
      const key = Object.keys(this.tool.aiPrompts)[0];
      this.history[key] = this.history[key].slice(0, this.editingMessage);
      await this.onGenerateClick(key, this.editedMessage);

      this.editingMessage = -1;
      this.editedMessage = "";
      this.isWarningOpen = true;
    }
  };

  handleSubmit = async () => {
    this.editRef.current.innerHTML = "";
    const inputToSend = this.input;
    this.input = "";
    await this.onGenerateClick(
      Object.keys(this.tool.aiPrompts)[0],
      inputToSend
    );
  };

  scrollToBottom = () => {
    if (!this.endRef) return;
    this.endRef.scrollIntoView({ behavior: "smooth" });
  };

  removeDissapearingMessages = elements => {
    this.dissapearingMessages.forEach(el => {
      if (elements.includes(el.key)) {
        el.dontShow = true;
      }
    });
  };

  onGenerateClick = async (key, input) => {
    try {
      this.loading = true;
      const postObj = {};

      if (this.editingMessage > -1) {
        this.editingMessage = -1;
        this.editedMessage = "";
      }

      if (!this.history[key]) {
        this.history[key] = [];
      }
      this.prompts[0].prompts
        .filter(el => checkIfValue(el.type))
        .forEach(prompt => {
          const attr = prompt.attr.replace(`[${key}] `, "");
          const value = input;

          if (
            this.tool.aiPrompts[
              Object.keys(this.tool.aiPrompts)[this.currentPrompt || 0]
            ].model === "gpt-3.5-turbo-chat" ||
            this.tool.aiPrompts[
              Object.keys(this.tool.aiPrompts)[this.currentPrompt || 0]
            ].model === "gpt-4-chat"
          ) {
            if (this.history[key].length > 0) {
              postObj["--internal-history"] = [...this.history[key]];
            }

            this.history[key].push({ role: "user", content: value });
            this.messages.push({
              role: "user",
              content: value,
              key: Math.random(),
            });
          }
          postObj[attr] = value;
        });

      this.scrollToBottomFnc();

      postObj.currentPrompt = key;
      if (this.prompts[0].n) {
        postObj.n = this.prompts[this.currentPrompt].n;
      }

      postObj.tool = this.tool.id;
      postObj.visitorId = this.props.store.getVisitorId();
      postObj.toolSession = this.toolSession;
      postObj.shouldLock = this.shouldLock;

      this.lastMessage = "";
      this.errorMessage = "";

      const sessionId = uuidv4();

      this.ws.current = new WebSocket(
        `${config.ws}/shared-tool/${sessionId}`,
        this.tokenId
      );

      this.lastPostObj = postObj;

      this.ws.current.onopen = () => {
        this.ws.current.send(
          JSON.stringify({
            session: sessionId,
            data: postObj,
          })
        );
      };
      this.ws.current.onmessage = ev => {
        const data = JSON.parse(ev.data);
        if (data.type === "done") {
          this.ws.current.close();
          return;
        }
        if (data.type === "error") {
          this.errorMessage = data.message;
          this.showRetry = data.showRetry;
          this.ws.current.close();
          return;
        }
        this.lastMessage += data.message ? data.message : "";
        this.scrollToBottom();
      };
      this.ws.current.onclose = () => {
        if (this.lastMessage) {
          this.history[key].push({
            role: "assistant",
            content: this.lastMessage,
          });
          this.messages.push({
            role: "assistant",
            content: this.lastMessage,
            key: Math.random(),
          });

          if (this.shouldHaveDissapearingMessages) {
            if (this.introMessage) {
              const randomKey = Math.random();
              this.dissapearingMessages.push({
                role: "assistent",
                content: this.introMessage,
                key: randomKey,
              });
              setTimeout(() => {
                this.removeDissapearingMessages([randomKey]);
              }, 9500);
              this.introMessage = "";
            }

            const dissapear = this.messages.slice(0, -2);
            this.dissapearingMessages.push(...dissapear);
            setTimeout(() => {
              this.removeDissapearingMessages(dissapear.map(el => el.key));
            }, 9500);
            this.messages = this.messages.slice(-2);
          }
        }
        this.loading = false;
        this.scrollToBottomFnc();
        if (this.shouldLock) {
          this.shouldLock = false;
          this.props.store.setLockedTools(this.tool.id);
        }
      };
      this.ws.current.onerror = error => {
        console.log("Error", error);
        this.errorMessage = !error.target.protocol
          ? this.tool.customMessages?.failMessage ||
            `The user may not be authenticated or not have enough credits.`
          : this.tool.customMessages?.privateRelayMessage ||
            "Oops! Message processing failed. Please try again.";
        this.showRetry = !!error.target.protocol;
      };
    } catch (error) {
      console.log("WS Error: ", error);
    }

    this.scrollToBottomFnc();
    return true;
  };

  onRetryClick = async key => {
    try {
      this.errorMessage = "";
      this.lastMessage = "";
      this.loading = true;

      if (this.editingMessage > -1) {
        this.editingMessage = -1;
        this.editedMessage = "";
      }

      const sessionId = uuidv4();
      this.ws.current = new WebSocket(
        `${config.ws}/shared-tool/${sessionId}`,
        this.tokenId
      );

      this.ws.current.onopen = () => {
        this.ws.current.send(
          JSON.stringify({
            session: sessionId,
            data: this.lastPostObj,
          })
        );
      };
      this.ws.current.onmessage = ev => {
        const data = JSON.parse(ev.data);
        if (data.type === "done") {
          this.ws.current.close();
          return;
        }
        if (data.type === "error") {
          this.errorMessage = data.message;
          this.showRetry = data.showRetry;
          this.ws.current.close();
          return;
        }
        this.lastMessage += data.message ? data.message : "";
        this.scrollToBottom();
      };

      this.ws.current.onclose = () => {
        if (this.lastMessage) {
          this.history[key].push({
            role: "assistant",
            content: this.lastMessage,
          });
          this.messages.push({
            role: "assistant",
            content: this.lastMessage,
            key: Math.random(),
          });

          if (this.shouldHaveDissapearingMessages) {
            if (this.introMessage) {
              const randomKey = Math.random();
              this.dissapearingMessages.push({
                role: "assistent",
                content: this.introMessage,
                key: randomKey,
              });
              setTimeout(() => {
                this.removeDissapearingMessages([randomKey]);
              }, 9500);
              this.introMessage = "";
            }

            const dissapear = this.messages.slice(0, -2);
            this.dissapearingMessages.push(...dissapear);
            setTimeout(() => {
              this.removeDissapearingMessages(dissapear.map(el => el.key));
            }, 9500);
            this.messages = this.messages.slice(-2);
          }
        }
        this.loading = false;
        this.scrollToBottomFnc();
      };

      this.ws.current.onerror = error => {
        console.log("Error", error);
        this.errorMessage = !error.target.protocol
          ? this.tool.customMessages?.failMessage ||
            `The user may not be authenticated or not have enough credits.`
          : this.tool.customMessages?.privateRelayMessage ||
            "Oops! Message processing failed. Please try again.";
        this.showRetry = !!error.target.protocol;
      };
    } catch (error) {
      console.log(error);
    }

    this.scrollToBottomFnc();
    return true;
  };

  shouldHaveMargin = text => !/^([0-9].)/.test(text);
  scrollToBottomFnc = () => {
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  };

  render() {
    if (!this.prompts.length) return null;
    if (this.shouldShowLockScreen)
      return (
        <>
          <Helmet>
            <title>{`${this.tool.title} Shared Tool Chat (Locked) - Vuo AI - ${this.tokenId}`}</title>
          </Helmet>
          <LockedScreen
            darkMode={this.isDarkMode}
            title={this.tool.title}
            message={this.tool.customMessages?.lockedMessage}
            url={this.tool.options?.url}
            urlText={this.tool.options?.urlText}
          />
        </>
      );
    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} Shared Tool Chat - Vuo AI - ${this.tokenId}`}</title>
        </Helmet>
        <div
          className={`h-full ${
            this.isDarkMode ? "bg-dark" : "bg-white"
          } overflow-hidden`}
        >
          <div className={`h-90 ${this.isDarkMode ? "bg-dark" : "bg-white"}`}>
            {!this.tool.options?.disableHeader && (
              <div
                className={`flex flex-row my-4 mx-12 items-center justify-between ${
                  this.isDarkMode ? "bg-dark" : "bg-white"
                }`}
              >
                <div className="flex flex-row items-center">
                  <div className="w-5 h-5 bg-purple-700 rounded-full" />
                  <div
                    className={`mx-2 text-xl ${
                      this.isDarkMode ? "text-white" : ""
                    }`}
                  >
                    {this.tool.title}
                  </div>
                </div>
              </div>
            )}

            <div
              className={`w-full ${
                this.isDarkMode ? "bg-chat-dark" : "bg-chat"
              } ${
                this.tool.options?.disableHeader
                  ? `${
                      this.shouldHaveDissapearingMessages &&
                      this.tool.options?.disableVhLimits
                        ? ""
                        : "h-without-header"
                    } h-90`
                  : `${
                      this.shouldHaveDissapearingMessages &&
                      this.tool.options?.disableVhLimits
                        ? ""
                        : "h-with-header"
                    } h-5/6`
              }`}
            >
              <div
                className={`h-full pt-2 overflow-auto flex flex-col ${
                  this.shouldHaveDissapearingMessages ? "chat-scroll" : ""
                }`}
              >
                {this.introMessage && (
                  <div className="flex flex-row py-4 lg:px-40 md:px-20 px-10 items-center">
                    <SparklesIcon
                      className={`my-4 w-6 h-6 ${
                        this.isDarkMode ? "text-white" : ""
                      }`}
                    />
                    <div
                      className={`py-4 w-full mx-2 ${
                        this.isDarkMode ? "text-white" : ""
                      }`}
                    >
                      <TypistWrapper
                        condition
                        onLineCallback={() => this.scrollToBottom()}
                        content={this.tool.introMessage
                          .split("\n")
                          .map((text, id, ar) => (
                            <p
                              className={
                                this.shouldHaveMargin(text) &&
                                id !== ar.length - 1
                                  ? "mb-5"
                                  : id < ar.length - 1 &&
                                    !this.shouldHaveMargin(text) &&
                                    this.shouldHaveMargin(ar[id + 1])
                                  ? "mb-5"
                                  : ""
                              }
                              key={id}
                            >
                              {text}
                            </p>
                          ))}
                      />
                    </div>
                  </div>
                )}
                {this.dissapearingMessages.map(
                  (el, idx) =>
                    !el.dontShow && (
                      <div
                        className={`flex flex-row py-4 lg:px-40 md:px-20 px-10 collapsed ${
                          el.role === "user"
                            ? `${
                                this.isDarkMode
                                  ? "bg-dark border-gray-800"
                                  : "bg-white"
                              } border`
                            : ""
                        }`}
                        key={idx}
                      >
                        {el.role === "user" ? (
                          <div className="h-full flex flex-1">
                            <UserIcon
                              className={`my-4 w-6 h-6 self-start ${
                                this.isDarkMode ? "text-white" : ""
                              }`}
                            />
                          </div>
                        ) : (
                          <div className="h-full flex flex-1">
                            <SparklesIcon
                              className={`my-4 w-6 h-6 self-start ${
                                this.isDarkMode ? "text-white" : ""
                              }`}
                            />
                          </div>
                        )}
                        {this.editingMessage !== idx ? (
                          <div
                            className={`py-4 w-full mx-2 ${
                              this.isDarkMode ? "text-white" : ""
                            }`}
                          >
                            <TypistWrapper
                              condition={false}
                              onLineCallback={() => this.scrollToBottom()}
                              content={el.content
                                .split("\n")
                                .map((text, id, ar) => (
                                  <p
                                    className={
                                      this.shouldHaveMargin(text) &&
                                      id !== ar.length - 1
                                        ? "mb-5"
                                        : id < ar.length - 1 &&
                                          !this.shouldHaveMargin(text) &&
                                          this.shouldHaveMargin(ar[id + 1])
                                        ? "mb-5"
                                        : ""
                                    }
                                    key={id}
                                  >
                                    {text}
                                  </p>
                                ))}
                            />
                          </div>
                        ) : (
                          <div
                            className={`py-4 w-full mx-2 border ${
                              this.isDarkMode
                                ? "border-gray-300 text-white"
                                : ""
                            }`}
                            contentEditable
                            onInput={e => {
                              this.editedMessage = e.currentTarget.innerText;
                            }}
                            suppressContentEditableWarning
                            onKeyDown={this.handleMessageEditPress}
                          >
                            {this.editedMessage
                              .split("\n")
                              .map((text, id, ar) => (
                                <p
                                  className={
                                    this.shouldHaveMargin(text) &&
                                    id !== ar.length - 1
                                      ? "mb-5"
                                      : id < ar.length - 1 &&
                                        !this.shouldHaveMargin(text) &&
                                        this.shouldHaveMargin(ar[id + 1])
                                      ? "mb-5"
                                      : ""
                                  }
                                  key={id}
                                >
                                  {text}
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    )
                )}
                {this.messages.map((el, idx) => (
                  <div
                    className={`flex flex-row py-4 lg:px-40 md:px-20 px-10 ${
                      el.role === "user"
                        ? `${
                            this.isDarkMode
                              ? "bg-dark border-gray-800"
                              : "bg-white"
                          } border`
                        : ""
                    }`}
                    key={idx}
                  >
                    {el.role === "user" ? (
                      <div className="h-full flex flex-1">
                        <UserIcon
                          className={`my-4 w-6 h-6 self-start ${
                            this.isDarkMode ? "text-white" : ""
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="h-full flex flex-1">
                        <SparklesIcon
                          className={`my-4 w-6 h-6 self-start ${
                            this.isDarkMode ? "text-white" : ""
                          }`}
                        />
                      </div>
                    )}
                    {this.editingMessage !== idx ? (
                      <div
                        className={`py-4 w-full mx-2 ${
                          this.isDarkMode ? "text-white" : ""
                        }`}
                      >
                        <TypistWrapper
                          condition={false}
                          onLineCallback={() => this.scrollToBottom()}
                          content={el.content
                            .split("\n")
                            .map((text, id, ar) => (
                              <p
                                className={
                                  this.shouldHaveMargin(text) &&
                                  id !== ar.length - 1
                                    ? "mb-5"
                                    : id < ar.length - 1 &&
                                      !this.shouldHaveMargin(text) &&
                                      this.shouldHaveMargin(ar[id + 1])
                                    ? "mb-5"
                                    : ""
                                }
                                key={id}
                              >
                                {text}
                              </p>
                            ))}
                        />
                      </div>
                    ) : (
                      <div
                        className={`py-4 w-full mx-2 border ${
                          this.isDarkMode ? "border-gray-300 text-white" : ""
                        }`}
                        contentEditable
                        onInput={e => {
                          this.editedMessage = e.currentTarget.innerText;
                        }}
                        suppressContentEditableWarning
                        onKeyDown={this.handleMessageEditPress}
                      >
                        {this.editedMessage.split("\n").map((text, id, ar) => (
                          <p
                            className={
                              this.shouldHaveMargin(text) &&
                              id !== ar.length - 1
                                ? "mb-5"
                                : id < ar.length - 1 &&
                                  !this.shouldHaveMargin(text) &&
                                  this.shouldHaveMargin(ar[id + 1])
                                ? "mb-5"
                                : ""
                            }
                            key={id}
                          >
                            {text}
                          </p>
                        ))}
                      </div>
                    )}
                    {!this.loading &&
                      this.editingMessage !== idx &&
                      !this.tool.options?.disableEditMessages &&
                      el.role === "user" && (
                        <Shortcut
                          className="p-1 rounded-lg cursor-pointer relative group flex flex-col items-center group -mr-6"
                          onClick={() => {
                            if (this.loading) return;
                            this.editingMessage = idx;
                            this.editedMessage = el.content;
                          }}
                        >
                          <PencilAltIcon
                            className={`my-4 w-4 h-4 ${
                              this.isDarkMode ? "text-white" : ""
                            }`}
                          />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span
                              className={`relative z-10 p-3 text-sm leading-none ${
                                this.isDarkMode
                                  ? "text-white bg-gray-500"
                                  : "text-gray-800 bg-white"
                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                            >
                              Edit message
                            </span>
                          </Tooltip>
                        </Shortcut>
                      )}
                    {this.editingMessage === idx && (
                      <div className="flex flex-col justify-around">
                        <Shortcut
                          className="p-1 rounded-lg cursor-pointer relative group flex flex-col items-center group -mr-6"
                          onClick={async () => {
                            this.prevMessages = this.messages;
                            this.messages = this.messages.slice(0, idx);
                            const key = Object.keys(this.tool.aiPrompts)[0];
                            this.history[key] = this.history[key].slice(0, idx);
                            await this.onGenerateClick(key, this.editedMessage);

                            this.editingMessage = -1;
                            this.editedMessage = "";
                            this.isWarningOpen = true;
                          }}
                        >
                          <CheckIcon className="w-4 h-4 text-green-500" />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span
                              className={`relative z-10 p-3 text-sm leading-none ${
                                this.isDarkMode
                                  ? "text-white bg-gray-500"
                                  : "text-gray-800 bg-white"
                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                            >
                              Save & Send
                            </span>
                          </Tooltip>
                        </Shortcut>
                        <Shortcut
                          className="p-1 rounded-lg cursor-pointer relative group flex flex-col items-center group -mr-6"
                          onClick={() => {
                            this.editingMessage = -1;
                            this.editedMessage = "";
                          }}
                        >
                          <XIcon className="w-4 h-4 text-red-500" />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span
                              className={`relative z-10 p-3 text-sm leading-none ${
                                this.isDarkMode
                                  ? "text-white bg-gray-500"
                                  : "text-gray-800 bg-white"
                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                            >
                              Cancel
                            </span>
                          </Tooltip>
                        </Shortcut>
                      </div>
                    )}
                  </div>
                ))}
                {this.loading && this.lastMessage === "" && (
                  <div className="flex flex-row py-4 lg:px-40 md:px-20 px-10 items-center">
                    <SparklesIcon
                      className={`my-4 w-6 h-6 ${
                        this.isDarkMode ? "text-white" : ""
                      }`}
                    />
                    <div className="py-4 w-full mx-2">
                      <div className="dot-flashing ml-4" />
                    </div>
                  </div>
                )}
                {this.loading &&
                  this.lastMessage &&
                  this.lastMessage.length && (
                    <div className="flex flex-row py-4 lg:px-40 md:px-20 px-10 h-content">
                      <div className="h-full flex flex-1">
                        <SparklesIcon
                          className={`my-4 w-6 h-6 self-start ${
                            this.isDarkMode ? "text-white" : ""
                          }`}
                        />
                      </div>
                      <div
                        className={`py-4 w-full mx-2 ${
                          this.isDarkMode ? "text-white" : ""
                        }`}
                      >
                        <TypistWrapper
                          condition={false}
                          onLineCallback={() => this.scrollToBottom()}
                          content={this.lastMessage
                            .split("\n")
                            .map((text, id, ar) => (
                              <p
                                className={
                                  this.shouldHaveMargin(text) &&
                                  id !== ar.length - 1
                                    ? "mb-5"
                                    : id < ar.length - 1 &&
                                      !this.shouldHaveMargin(text) &&
                                      this.shouldHaveMargin(ar[id + 1])
                                    ? "mb-5"
                                    : ""
                                }
                                key={id}
                              >
                                {text}
                              </p>
                            ))}
                        />
                      </div>
                    </div>
                  )}
                {this.errorMessage && this.errorMessage.length && (
                  <div className="flex flex-row py-4 lg:px-40 md:px-20 px-10 items-center">
                    <SparklesIcon className="my-4 w-6 h-6" />
                    <div className="py-4 w-full mx-2 text-red-500 flex flex-row">
                      <TypistWrapper
                        condition={false}
                        onLineCallback={() => this.scrollToBottom()}
                        content={this.errorMessage
                          .split("\n")
                          .map((text, id, ar) => (
                            <p
                              className={
                                this.shouldHaveMargin(text) &&
                                id !== ar.length - 1
                                  ? "mb-5"
                                  : id < ar.length - 1 &&
                                    !this.shouldHaveMargin(text) &&
                                    this.shouldHaveMargin(ar[id + 1])
                                  ? "mb-5"
                                  : ""
                              }
                              key={id}
                            >
                              {text}
                            </p>
                          ))}
                      />
                      {this.showRetry && (
                        <button
                          onClick={() =>
                            this.onRetryClick(
                              Object.keys(this.tool.aiPrompts)[0]
                            )
                          }
                        >
                          <IconRefresh />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={el => {
                    this.endRef = el;
                  }}
                />
              </div>
            </div>

            <form
              className={`relative flex flex-1 h-1/6 min-footer w-full pt-6 ${
                this.isDarkMode ? "bg-dark" : "bg-white"
              }`}
              onSubmit={async e => {
                e.preventDefault();
                await this.handleSubmit();
              }}
            >
              <div className="mx-2">
                <div
                  contentEditable={!this.isLocked}
                  ref={this.editRef}
                  className={`absolute bottom-chat inset-x-14 sm:inset-x-28 block p-2 pr-10 text-md ${
                    this.isDarkMode
                      ? "text-white bg-dark border-gray-300"
                      : "text-gray-900 bg-white border-gray-300"
                  } border rounded-md focus:ring-1 focus:ring-purple-500 focus:border-purple-500 h-max min-chat-input`}
                  id="message-box"
                  onInput={e => (this.input = e.currentTarget.innerText)}
                  onKeyDown={this.handleKeyPress}
                />
              </div>
              <div className="absolute bottom-chat-input right-14 sm:right-28 flex items-center pr-7">
                <button
                  type="submit"
                  disabled={
                    this.loading ||
                    this.input.trim().length === 0 ||
                    this.isLocked
                  }
                >
                  <PaperAirplaneIcon
                    className={`w-6 h-6 transform rotate-45 ${
                      this.isDarkMode
                        ? "text-white hover:fill-gray-200 hover:text-gray-200"
                        : "hover:fill-blue-500 hover:text-purple-500"
                    }`}
                  />
                </button>
              </div>
            </form>

            {this.isWarningOpen ? (
              <Modal
                showModal={this.isWarningOpen}
                setShowModal={() => {
                  this.isWarningOpen = false;
                  this.editingMessage = -1;
                  this.editedMessage = "";
                }}
                action={() => {
                  this.messages = this.prevMessages;
                  if (this.loading) {
                    this.ws.current.close();
                    this.lastMessage = "";
                    this.loading = false;
                  }
                }}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const Modal = observer(({ showModal, setShowModal, action }) => (
  <SmallPopup
    title="Conversation cleared"
    showModal={showModal}
    setShowModal={setShowModal}
    action={action}
    yesText="Undo"
    noText="Ok"
  />
));

export default withRouter(SharedToolChat);
