import { ThemeProvider } from "styled-components";
import React, { Component } from "react";

import { HelmetProvider } from "react-helmet-async";

import { Provider, observer } from "mobx-react";
import ReactGA from "react-ga";

import colors from "tailwindcss/colors";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from "./Header";

import AppStore from "./store";
import Search from "./Search";
import Pricing from "./Pricing"; // <--- Add this line

import Dashboard from "./Dashboard";
import PersonalDashboard from "./PersonalDashboard";

import Tool from "./Core/Tool";
import Chat from "./Core/Chat";

import Login from "./Login/Login";

import Profile from "./Profile";
import LoginSuccess from "./Login/Success";

import NewTool from "./NewTool";
import SharedTool from "./Core/SharedTool";

import "./App.scss";
import ChangePassword from "./ChangePassword/ChangePassword";
import ToolChat from "./Core/ToolChat";
import SharedToolChat from "./Core/SharedToolChat";

if (!window.store) {
  window.store = new AppStore();
}

ReactGA.initialize("G-J3RCRKE9CJ");

@observer
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={colors}>
        <HelmetProvider>
          <Provider store={window.store}>
            <Router>
              {window.store.redirect ? (
                <Redirect to={window.store.redirect} />
              ) : null}
              {window.store.isLoggedIn ? (
                <>
                  {window.store.profile.status ? (
                    <>
                      {" "}
                      {/*  Logged in with plan */}
                      <Switch>
                        <Route path="/writing/document">
                          <div />
                        </Route>
                        <Route component={Header} />
                      </Switch>
                      <Switch>
                        <Route path="/" exact component={Dashboard} />
                        <Route
                          path="/my-tools"
                          exact
                          component={PersonalDashboard}
                        />
                        <Route path="/search" exact component={Search} />

                        <Route path="/ai/">
                          <Switch>
                            <Route path="/ai/code/debugging" component={Chat} />
                            <Route component={Tool} />
                          </Switch>
                        </Route>
                        <Route path="/ai-chat/">
                          <Switch>
                            <Route component={ToolChat} />
                          </Switch>
                        </Route>
                        <Route path="/my-profile" component={Profile} />
                        <Route path="/new-tool" component={NewTool} />
                        <Route
                          path="/edit-tool/:user/:tool"
                          component={NewTool}
                        />
                        <Route path="/signup/failed" component={Profile} />
                        <Route
                          path="/signup/success"
                          component={LoginSuccess}
                        />
                        <Route
                          path="/signup/success"
                          component={LoginSuccess}
                        />
                      </Switch>
                    </>
                  ) : (
                    <>
                      {" "}
                      {/* Logged in but no plan */}
                      <Switch>
                        <Route
                          path="/signup/success"
                          component={LoginSuccess}
                        />
                        <Route>
                          <Pricing />
                        </Route>
                      </Switch>
                    </>
                  )}{" "}
                </>
              ) : (
                <>
                  {" "}
                  {/*  Not Logged In */}
                  <Switch>
                    <Route path="/tools/:id" component={SharedTool} />
                    <Route path="/tools-chat/:id" component={SharedToolChat} />
                    <Route
                      path="/change-password/:id"
                      component={ChangePassword}
                    />
                    <Route path="/" exact>
                      <Redirect to="/login" />
                    </Route>
                    <Route path="/" component={Login} />
                  </Switch>
                </>
              )}
            </Router>
          </Provider>
        </HelmetProvider>
      </ThemeProvider>
    );
  }
}

export default App;
