import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { computed, observable, makeObservable } from "mobx";
import { Helmet } from "react-helmet-async";
import { observer, inject } from "mobx-react";

import MainBody from "./Components/Body";
import BasicModal from "./Components/BasicModal";

@inject("store")
@observer
class Body extends Component {
  @observable showDeleteModal;
  @observable toDeleteToolId;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get permissions() {
    return this.props.store.tools.filter(tool =>
      tool.permissions.some(r =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }

  toggleShowDeleteTool = (val = null, toolId = null) => {
    if (val !== null) {
      this.showDeleteModal = !this.showDeleteModal;
    } else {
      this.showDeleteModal = val;
    }
    this.toDeleteToolId = toolId;
  };

  async deleteTool() {
    await this.props.store.api.delete(`/tool/${this.toDeleteToolId}`);
    await this.props.store.init();
  }

  render() {
    const { uniqueCategories } = this.props.store;
    return (
      <>
        <Helmet>
          <title>Tools - Vuo AI</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          {uniqueCategories.length > 0 &&
            uniqueCategories.map(cat => {
              const list = this.permissions.filter(
                tool => tool.category === cat
              );
              return (
                list.length > 0 && (
                  <div key={cat}>
                    <Title title={cat} />
                    <Grid>
                      {list.map((tool, index) => (
                        <Tool
                          key={index}
                          group={tool.category}
                          title={tool.title}
                          uses={tool.uses}
                          createdBy={tool.createdBy}
                          toolId={tool.id}
                          searchKey={tool.searchKey}
                          to={tool.to}
                          Icon={tool.Icon}
                          desc={tool.desc}
                          fromColor={tool.fromColor}
                          toColor={tool.toColor}
                          canEdit={tool.apiCreated && tool.canEdit}
                          setShowModal={this.toggleShowDeleteTool}
                        />
                      ))}
                    </Grid>
                    <Divider />
                  </div>
                )
              );
            })}
        </MainBody>
        <Modal
          showModal={this.showDeleteModal}
          setShowModal={(val, id) => this.toggleShowDeleteTool(val, id)}
          action={() => this.deleteTool()}
        />
      </>
    );
  }
}

export function Divider() {
  return (
    <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
      {" "}
      <div />
      <div />
    </div>
  );
}

export function Title({ title }) {
  return (
    <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
      {title}
    </h2>
  );
}

export function Grid({ children }) {
  return (
    <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
      {children}
    </div>
  );
}

export const Tool = withRouter(
  ({
    title,
    uses,
    createdBy,
    toolId,
    searchKey,
    desc,
    to,
    group,
    fromColor,
    canEdit,
    history,
    setShowModal,
  }) => (
    <Link to={to || "/"} className="flex relative ">
      <div className="bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black">
        <div className="flex flex-col flex-1 p-4 w-full h-full">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col flex-1">
              <div
                className={`uppercase tracking-wide text-sm text-${
                  fromColor || "green-500"
                } font-semibold leading-none`}
              >
                {group || "New"}
              </div>
              <div
                href="#"
                className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
              >
                {`${title}`}
              </div>
              <div
                href="#"
                className="block text-sm leading-tight font-sm text-slate-400 leading-none"
              >
                {`@${createdBy}`}
              </div>
            </div>

            {canEdit && (
              <div className="flex flex-col hover:bg-sky-700">
                <button
                  className="hover:bg-sky-700 text-right"
                  onClick={e => {
                    e.preventDefault();
                    history.push(`/edit-tool/${createdBy}/${searchKey}`);
                  }}
                >
                  Edit
                </button>
                <button
                  className="hover:bg-sky-700 text-right"
                  onClick={e => {
                    e.preventDefault();
                    setShowModal(true, toolId);
                  }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
          <p className="flex mt-1 pr-1 text-sm h-full items-end justify-end">
            {`${uses > 0 ? uses : "No"} ${uses === 1 ? "run" : "runs"}`}
          </p>
        </div>
      </div>
    </Link>
  )
);

const Modal = observer(({ showModal, setShowModal, action }) => (
  <BasicModal
    title="Are you sure you want to delete the tool?"
    showModal={showModal}
    setShowModal={setShowModal}
    action={action}
    yesText="Delete"
    noText="Cancel"
  />
));

export default Body;
