import React from "react";
import Typist from "react-typist";

const TypistWrapper = ({ condition, onLineCallback, content }) =>
  condition ? (
    <Typist
      stdTypingDelay={0}
      avgTypingDelay={7}
      cursor={{
        show: false,
      }}
      onLineTyped={(_l, _i) => {
        if (!onLineCallback) return;
        onLineCallback();
      }}
    >
      {content}
    </Typist>
  ) : (
    content
  );

export default TypistWrapper;
