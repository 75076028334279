import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

@observer
class LockedScreeen extends Component {
  render() {
    const { title, message, darkMode, url, urlText } = this.props;
    return (
      <div
        className={`w-full h-full flex flex-1 text-center flex-col items-center justify-center ${
          darkMode ? "bg-black text-white" : "bg-white"
        }`}
      >
        <div className="text-3xl text-purple-700 font-bold pb-8">{title}</div>
        <div className="pb-8">{message}</div>

        {url && urlText && (
          <a
            href={url}
            rel="noreferrer"
            target="_blank"
            className={`p-4 px-8 rounded-md ${
              darkMode
                ? "bg-dark hover:bg-gray-500"
                : "bg-gray-300 hover:bg-gray-500 hover:text-white"
            }`}
          >
            {urlText}
          </a>
        )}
      </div>
    );
  }
}

export default withRouter(LockedScreeen);
