import {
  checkIfConditionalOutput,
  checkIfHeading,
  checkIfNumberValue,
  checkIfOutputNumberValue,
  checkIfOutputSubmit,
  checkIfSubmit,
} from "../../NewTool/helper";

class PreviewTool {
  prompt = {};
  uiElements = {};
  aiPrompts = [];
  output = {};
  fromColor = {};
  toColor = {};
  historyId = "";

  constructor(prompt, aiPrompts, uiElements, historyId) {
    this.prompt = prompt;
    this.aiPrompts = aiPrompts;
    this.uiElements = uiElements;
    this.output = {
      title: prompt.title,
      desc: "The following key points detected",
      color: "blue",
    };
    this.fromColor = "gray-500";
    this.toColor = "gray-500";
    this.historyId = historyId;
    this.history = 0;
  }

  static fromTool(json, history) {
    const prompt = {
      title: json.toolName,
      desc: "Complete the following form",
    };
    const uiElements = json.uiStructure.map(el => {
      const inHistory =
        history && history.find(h => el.uiValue && h.attr === el.uiValue);

      const value = checkIfHeading(el.uiType)
        ? el.uiText
        : checkIfSubmit(el.uiType) ||
          checkIfOutputSubmit(el.uiType) ||
          checkIfConditionalOutput(el.uiType)
          ? el.uiValue
          : inHistory
            ? inHistory.value
            : checkIfNumberValue(el.uiType) || checkIfOutputNumberValue(el.uiType)
              ? el.min || 1
              : "";

      return {
        title: el.title,
        attr: el.uiValue,
        value,
        min: el.min,
        max: el.max,
        condition: el.condition,
        options: el.uiOptions,
        type: el.uiType,
        required: false,
        maxLength: 2500,
      };
    });
    return new PreviewTool(prompt, json.prompts, uiElements, json.historyId);
  }
}

export default PreviewTool;
